import { useAnnotations } from "@/stores/useDocumentAnnotations";

export const useDocumentAnnotationsStore = (documentId: string) => {
  const { revision } = useSubmittedDocumentStore(documentId);

  if (!revision) {
    throw new Error("Revision is not defined");
  }

  return useAnnotations(documentId, revision.id)();
};
