import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import pdfmake_client_PXHi28cJi9 from "/vercel/path0/node_modules/nuxt-pdfmake/dist/runtime/pdfmake.client.mjs";
import floating_vue_EIcJ7xiw0h from "/vercel/path0/.nuxt/floating-vue.mjs";
import plugin_client_mik7N71GmK from "/vercel/path0/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import templates_plugin_62c509d4_V0IM0BWhEX from "/vercel/path0/.nuxt/templates.plugin.62c509d4.ejs";
import plugin_client_mLgZJrmjnn from "/vercel/path0/node_modules/nuxt-vuefire/dist/runtime/auth/plugin.client.mjs";
import BlockHorizontalScrollNavigation_VNLt0lpDnz from "/vercel/path0/plugins/BlockHorizontalScrollNavigation.ts";
import algolia_client_nu1qKrS9ie from "/vercel/path0/plugins/algolia.client.ts";
import chartjs_client_N7GULTnRpX from "/vercel/path0/plugins/chartjs.client.ts";
import firebase_Wjz9XrZo01 from "/vercel/path0/plugins/firebase.ts";
import fontawesome_klhsrycjcK from "/vercel/path0/plugins/fontawesome.js";
import google_api_client_ZAAXxvCyLq from "/vercel/path0/plugins/google-api.client.ts";
import imask_nTmTWqzxUs from "/vercel/path0/plugins/imask.ts";
import iubenda_client_RbEsJgYVxW from "/vercel/path0/plugins/iubenda.client.ts";
import primevue_client_tAe5RSqVAg from "/vercel/path0/plugins/primevue.client.ts";
import quill_delta_client_aHcmb59VsO from "/vercel/path0/plugins/quill-delta.client.ts";
import vue_final_modal_pML93k5qcp from "/vercel/path0/plugins/vue-final-modal.ts";
import vue_toast_client_UNB1AEVrIW from "/vercel/path0/plugins/vue-toast.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  pdfmake_client_PXHi28cJi9,
  floating_vue_EIcJ7xiw0h,
  plugin_client_mik7N71GmK,
  templates_plugin_62c509d4_V0IM0BWhEX,
  plugin_client_mLgZJrmjnn,
  BlockHorizontalScrollNavigation_VNLt0lpDnz,
  algolia_client_nu1qKrS9ie,
  chartjs_client_N7GULTnRpX,
  firebase_Wjz9XrZo01,
  fontawesome_klhsrycjcK,
  google_api_client_ZAAXxvCyLq,
  imask_nTmTWqzxUs,
  iubenda_client_RbEsJgYVxW,
  primevue_client_tAe5RSqVAg,
  quill_delta_client_aHcmb59VsO,
  vue_final_modal_pML93k5qcp,
  vue_toast_client_UNB1AEVrIW
]