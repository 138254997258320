import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { EvaluationResults } from "~/types/EvaluationResults";
import { EvaluationResultsStatus } from "~/types/enums/EvaluationResultsStatus";

export const useEvaluateChecklistCriteria = async (
  documentId: string,
  revisionId: string,
  criteriaId: string,
  reevaluate: boolean = false,
  bypassCache: boolean = false
) => {
  // Ok let's update the evalution results document with a status
  const db = useFirestore();
  const ref = doc(
    db,
    `/documents/${documentId}/revisions/${revisionId}/evaluationResults/${criteriaId}`
  );

  const snapshot = await getDoc(ref);

  const timestamp = new Date().getTime();

  try {
    if (snapshot.exists()) {
      const evaluationResults = snapshot.data() as EvaluationResults;

      if (evaluationResults.status != EvaluationResultsStatus.success) {
        await updateDoc(ref, {
          status: EvaluationResultsStatus.evaluating,
          lastUpdatedTimestamp: timestamp,
        });
      }
    } else {
      await setDoc(ref, {
        status: EvaluationResultsStatus.evaluating,
        createdOnTimestamp: timestamp,
        lastUpdatedTimestamp: timestamp,
      });
    }
  } catch (error) {}
  const headers = await useApiHeaders();
  try {
    $fetch(`/api/${documentId}/${revisionId}/${criteriaId}/evaluate`, {
      method: "POST",
      headers: headers,
      body: {
        reevaluate: reevaluate,
        bypassCache: bypassCache,
      },
    });
  } catch (error) {
    updateDoc(ref, {
      status: EvaluationResultsStatus.failed,
      lastUpdatedTimestamp: new Date().getTime(),
    });
  }
};
