import "vue-toast-notification/dist/theme-sugar.css";

export const useBaseToast = async (
  message: string,
  type: "success" | "error" | "warning"
) => {
  if (!process.client) return;

  const { useToast } = await import("vue-toast-notification");

  const toast = useToast();
  toast.open({
    message,
    type,
    duration: 1000,
    position: "top",
    dismissible: true,
  });
};

// export const useBaseToast = async (
//   message: string,
//   type: "success" | "error" | "warning"
// ) => {
//   if (process.client) {
//     const { useToast } = await import("vue-toastification");

//     const toast = useToast();

//     switch (type) {
//       case "error":
//         toast.error(message, { timeout: 1000 });
//         break;
//       case "warn":
//         toast.warning(message, { timeout: 1000 });
//         break;
//       case "success":
//       default:
//         toast.success(message, { timeout: 1000 });
//         break;
//     }
//   }
// };
