<script lang="ts" setup>
const props = defineProps(["modelValue", "allowMultiple"]);
const emit = defineEmits(["update:modelValue"]);

const tourBuilderStore = useTourBuilder();
const { isSelectingElement } = storeToRefs(tourBuilderStore);

const chooseElement = ref(false);
const onChooseElement = () => {
  chooseElement.value = true;
  isSelectingElement.value = true;
};

const onElementChosen = (elementId: string) => {
  chooseElement.value = false;
  isSelectingElement.value = false;

  if (props.allowMultiple != true) {
    emit("update:modelValue", elementId);
  } else {
    let currentValue = props.modelValue as string[];

    if (currentValue == undefined) {
      currentValue = [];
    }

    emit("update:modelValue", [...currentValue, elementId]);
  }
};

const val = computed(() => {
  if (props.allowMultiple != true) {
    return props.modelValue;
  }

  return (props.modelValue as string[]).join(", ") || "";
});

const onUpdate = (value: string) => {
  if (props.allowMultiple != true) {
    emit("update:modelValue", value);
  } else {
    emit(
      "update:modelValue",
      value.split(",").map((v) => v.trim())
    );
  }
};
</script>

<template>
  <div>
    <div v-if="chooseElement != true">
      <BaseTextButton
        v-if="modelValue == undefined || modelValue == ''"
        @click="onChooseElement"
      >
        Choose Element
      </BaseTextButton>
      <div
        class="flex flex-row items-center"
        v-if="modelValue != undefined && modelValue != ''"
      >
        <BaseIconButton
          color="danger"
          @click="$emit('update:modelValue', undefined)"
        >
          <icon name="material-symbols:close" />
        </BaseIconButton>
        <span class="p-2 border-2 border-dashed border-primary-default">
          <BaseTextInput :model-value="val" @update:model-value="onUpdate" />
        </span>
        <BaseTextButton @click="onChooseElement">
          Change Element
        </BaseTextButton>
      </div>
    </div>
    <TourBuilderElementSelector
      v-if="chooseElement == true"
      @choose-element="onElementChosen"
    />
  </div>
</template>

<style scoped></style>
