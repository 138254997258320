import { GuidedTour, GuidedTours } from "classes/models/tours/guided-tour";
import { GuidedToursService } from "classes/models/tours/guided-tour-service";
import { Driver, driver, DriveStep, Config, State } from "driver.js";
import { Subscription } from "rxjs";

import { GuidedTourEventName } from "~/types/enums/GuidedTourEventNames.enum";

export const useGuidedTours = defineStore("/guidedTours", () => {
  const allTours = ref<GuidedTours>([]);
  const restartableTours = ref<GuidedTours>([]);
  const activeTour = ref<GuidedTour | undefined>(undefined);

  const isStreaming = ref(false);
  const allToursSubscription = ref<Subscription | undefined>();

  const streamAllTours = () => {
    if (isStreaming.value == true) return;

    allToursSubscription.value?.unsubscribe();
    allToursSubscription.value = GuidedToursService.streamAllTours().subscribe(
      (tours) => {
        allTours.value = tours;
      }
    );

    isStreaming.value = true;
  };

  const convertGuidedTourStepsToDriverSteps = (
    tour: GuidedTour,
    driverObj: Driver,
    steps: GuidedTourSteps
  ): DriveStep[] => {
    return steps.map((step) => {
      return convertGuidedTourStepToDriverStep(tour, driverObj, step);
    });
  };

  const convertGuidedTourStepToDriverStep = (
    tour: GuidedTour,
    driverObj: Driver,
    guidedStep: GuidedTourStep
  ): DriveStep => {
    const executeStepActions = async () => {
      if (guidedStep.onNextActions != undefined && driveStep.popover) {
        for (const action of guidedStep.onNextActions!) {
          if (["click", "scroll"].includes(action.type)) {
            const targetElementId = useStepId(
              action.targetElementIdentifier ?? ""
            );
            const targetElement = document.querySelector(
              targetElementId
            ) as HTMLElement;

            switch (action.type) {
              case "click":
                targetElement?.click();
                break;
              case "scroll":
                targetElement?.scrollIntoView({
                  behavior: "instant",
                  block: "center",
                  inline: "center",
                });
                break;
            }
          }

          if (action.type == "navigate" && action.navigationPath != undefined) {
            navigateTo(action.navigationPath);
          }

          if (action.type == "event" && action.eventName != undefined) {
            useTourEventBus().emit({
              name: action.eventName,
              data: action.eventData,
            });
          }

          if (action.delay) {
            // wait for the delay before continuing the loop
            await new Promise((resolve) => setTimeout(resolve, action.delay));
          }
        }

        // After the actions are done, figure out how to call the next one
        // I may need to instatiate a driver object first which would be fine I think.
      }
    };

    const driveStep = {
      popover: {
        title: guidedStep.title,

        // replace \n with <br/> to allow for line breaks
        description: guidedStep.instructions.replace(/\n/g, "<br/>"),
        popoverClass: guidedStep.popoverClass,
        nextBtnText: guidedStep.nextButtonText,
        doneBtnText: guidedStep.doneButtonText,
        showButtons: ["next"],
      },
    } as DriveStep;

    const isSmallScreen = window.innerWidth < 1350;
    const isHiddenOnSmallScreens = guidedStep.isTargetHiddenOnSmallScreens;
    const showTargetElement =
      isSmallScreen != true || isHiddenOnSmallScreens != true;

    if (showTargetElement && guidedStep.targetElementIdentifier != undefined) {
      driveStep.element = useStepId(guidedStep.targetElementIdentifier);
    }

    const onNextClick = async () => {
      window.removeEventListener("wheel", onBlockScroll);

      // First enable any disable elements if there are any:
      if (guidedStep.disableElementIdentifiers) {
        toggleElementsDisabledStatus(
          guidedStep.disableElementIdentifiers,
          false
        );
      }

      await executeStepActions();

      if (driverObj.hasNextStep()) {
        driverObj.moveNext();
      } else {
        addTourToRestartableTours(tour);

        useTourEventBus().emit({
          name: GuidedTourEventName.tourComplete,
          data: tour.id,
        });
        driverObj.destroy();
      }
    };

    const onBlockScroll = (event: WheelEvent) => {
      event.preventDefault();
    };

    driveStep.onHighlightStarted = () => {
      // Eventually
      if (guidedStep.blockScroll == true) {
        window.addEventListener("wheel", onBlockScroll, { passive: false });
      }
      if (guidedStep.targetElementIdentifier == undefined) return;

      const targetElement = document.querySelector(
        useStepId(guidedStep.targetElementIdentifier)
      );
      if (targetElement == undefined) return;

      // scroll to the target eleemnt
      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    };

    driveStep.onHighlighted = (
      element: Element | undefined,
      step: DriveStep,
      opts: {
        config: Config;
        state: State;
      }
    ) => {
      // If the popover has disable elements, do that here
      if (guidedStep.disableElementIdentifiers) {
        toggleElementsDisabledStatus(
          guidedStep.disableElementIdentifiers,
          true
        );
      }

      if (
        guidedStep.tiggerElementIdentifier != undefined &&
        driveStep.popover
      ) {
        const triggerElementIdentifier = useStepId(
          guidedStep.tiggerElementIdentifier!
        );
        const triggerElement = document.querySelector(triggerElementIdentifier);

        if (triggerElement) {
          const clickHandler = async (event: MouseEvent) => {
            if (event.target == undefined) return;

            const matchingElement = (event.target as HTMLElement).closest(
              triggerElementIdentifier
            );

            // Check if the event.target has the data-step-id
            const isTargetMatch =
              (event.target as HTMLElement).getAttribute("data-step-id") !=
              guidedStep.tiggerElementIdentifier;

            if (!matchingElement && !isTargetMatch) return;

            if (guidedStep.isIndirectTrigger == true) {
              document.body.removeEventListener("click", clickHandler);
            } else {
              (triggerElement as HTMLElement).removeEventListener(
                "click",
                clickHandler
              );
            }

            onNextClick();
          };

          if (guidedStep.isIndirectTrigger == true) {
            document.body.addEventListener("click", clickHandler);
          } else {
            (triggerElement as HTMLElement).addEventListener(
              "click",
              clickHandler
            );
          }
        }
      }
    };

    if (guidedStep.tiggerElementIdentifier != undefined && driveStep.popover) {
      driveStep.popover.showButtons = [];
    }

    driveStep.popover!.onNextClick = onNextClick;
    return driveStep;
  };

  const toggleElementsDisabledStatus = (
    elementIds: string[],
    shouldDisable: boolean
  ) => {
    const elements = elementIds.map(
      (id) => document.querySelector(useStepId(id)) as HTMLElement
    );

    for (const element of elements) {
      if (element == undefined) continue;

      if (shouldDisable) {
        // Crearte a new elemnet that covers this element and intercepts all pointer events and shows a nota llowed curor

        const box = element.getBoundingClientRect();

        const coverElement = document.createElement("div");
        coverElement.classList.add("step-disabled-element");
        coverElement.style.position = "fixed";
        coverElement.style.top = box.top + "px";
        coverElement.style.left = box.left + "px";
        coverElement.style.width = box.width + "px";
        coverElement.style.height = box.height + "px";
        coverElement.style.zIndex = "999999";
        coverElement.style.backgroundColor = "rgba(0,0,0,0.0)";
        coverElement.style.pointerEvents = "all";
        coverElement.style.cursor = "not-allowed";

        document.body.appendChild(coverElement);
      }
    }

    if (shouldDisable == false) {
      clearDisabledStatus();
    }
  };

  const clearDisabledStatus = () => {
    const disabledElements = document.querySelectorAll(
      ".step-disabled-element"
    );

    for (const element of disabledElements) {
      if (element == undefined) continue;

      element.remove();
    }
  };

  const createDriver = (
    guidedTour: GuidedTour,
    allowClose: boolean = false
  ) => {
    const driverObj = driver({
      allowKeyboardControl: false,
      showProgress: true,
      allowClose: allowClose,
      onDestroyed: () => {
        activeTour.value = undefined;
        clearDisabledStatus();
      },
      onPopoverRender: () => {
        const currentStepIndex = driverObj.getActiveIndex();

        if (currentStepIndex == undefined) return;

        const currentStep = guidedTour.steps[currentStepIndex];

        if (currentStep.tiggerElementIdentifier == undefined) return;
        const targetElementSelector = useStepId(
          currentStep.tiggerElementIdentifier
        );
        const targetElement = document.querySelector(targetElementSelector);

        if (targetElement != undefined) return;

        const activeStep = driverObj.getActiveStep();

        if (activeStep == undefined) return;

        // console.log(activeStep);
        activeStep.popover = {
          ...activeStep.popover,
          nextBtnText: "Next",
        };

        const stepMessage = document.getElementById(
          "driver-popover-description"
        );
        // Append a message to this html element if it exists

        if (stepMessage == undefined) return;

        const message = document.createElement("div");
        message.innerHTML =
          "Oops! We encountered a problem. Click Next to skip this step.";
        message.className =
          " text-center mt-2 text-[12px] italic text-danger-default";
        stepMessage.appendChild(message);

        const nextButtonElements = document.getElementsByClassName(
          "driver-popover-next-btn"
        );

        if (nextButtonElements == undefined || nextButtonElements.length == 0)
          return;

        const nextButtonElement = nextButtonElements[0] as HTMLElement;
        // Set display to block
        nextButtonElement.style.display = "block";
      },
    });

    const driverSteps = convertGuidedTourStepsToDriverSteps(
      guidedTour,
      driverObj,
      guidedTour.steps
    );

    driverObj.setSteps(driverSteps);

    return driverObj;
  };

  const tourToShow = ref<GuidedTour | undefined>(undefined);

  const showTourButton = computed(() => {
    if (tourToShow.value == undefined) return undefined;

    return tourToShow.value != undefined;
  });

  const setTourToShow = (tourId?: string) => {
    if (tourId == undefined) {
      tourToShow.value = undefined;
      return;
    }

    tourToShow.value = getTour(tourId);
  };

  const getTour = (tourId: string): GuidedTour | undefined => {
    return allTours.value.find((tour) => tour.id == tourId);
  };

  const startTour = (tour: GuidedTour, autoClose: boolean = false) => {
    tourToShow.value = undefined;
    activeTour.value = tour;
    const driver = useGuidedTours().createDriver(tour, autoClose);
    driver.drive();
  };

  const addTourToRestartableTours = (tour: GuidedTour) => {
    // First check if the tour is already in the restartable tours list
    const restartableToursIndex = restartableTours.value.findIndex(
      (t) => t.id == tour.id
    );

    if (tour.allowRestart && restartableToursIndex < 0) {
      restartableTours.value.push(tour);
    }
  };

  const hasActiveTour = computed(() => {
    return activeTour.value != undefined;
  });

  const setRestartableTours = (tours: GuidedTours) => {
    restartableTours.value = tours;
  };

  const clearToursToShow = () => {
    tourToShow.value = undefined;
    restartableTours.value = [];
  };

  return {
    allTours,
    restartableTours,
    showTourButton,
    tourToShow,
    getTour,
    setTourToShow,
    streamAllTours,
    createDriver,
    convertGuidedTourStepsToDriverSteps,
    convertGuidedTourStepToDriverStep,
    startTour,
    hasActiveTour,
    clearToursToShow,
    setRestartableTours,
  };
});
