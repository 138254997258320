<script lang="ts" setup>
const props = defineProps({
  guidedTourStep: {
    type: Object as PropType<GuidedTourStep>,
    required: true,
  },
});

const emit = defineEmits(["back", "choose-element"]);

const addAction = () => {
  const action: GuidedTourAction = {
    type: "click",
    targetElementIdentifier: "",
  };
  if (!props.guidedTourStep.onNextActions) {
    props.guidedTourStep.onNextActions = [];
  }
  props.guidedTourStep.onNextActions.push(action);
};

const removeAction = (index: number) => {
  props.guidedTourStep.onNextActions?.splice(index, 1);
};
</script>

<template>
  <div class="flex flex-col relative h-full w-full">
    <div class="flex flex-row items-center py-2 px-2">
      <BaseIconButton @click="$emit('back')">
        <icon name="material-symbols:arrow-back" />
      </BaseIconButton>
    </div>
    <div class="flex flex-col grow min-h-0 h-full overflow-auto p-4">
      <BaseTextInput v-model="guidedTourStep.title" class="mb-2" />
      <BaseTextInput
        v-model="guidedTourStep.popoverClass"
        placeholder="Popover class..."
        class="mb-2"
      />
      <BaseTextInput
        v-model="guidedTourStep.nextButtonText"
        placeholder="Next button text (optional)"
        class="mb-2"
      />
      <BaseTextInput
        v-model="guidedTourStep.doneButtonText"
        placeholder="Done button text (optional)"
        class="mb-2"
      />

      <BaseTextArea
        v-model="guidedTourStep.instructions"
        class="mb-2 h-[200px] shrink-0"
      />

      <BaseFormComponent>
        <template #label>Block Scroll? </template>
        <BaseToggle v-model="guidedTourStep.blockScroll" />
      </BaseFormComponent>
      <BaseFormComponent>
        <template #label> Target Element </template>
        <TourBuilderElementSelectorButton
          v-model="guidedTourStep.targetElementIdentifier"
        />
      </BaseFormComponent>
      <TransitionCollapsible>
        <BaseFormComponent v-if="guidedTourStep.targetElementIdentifier">
          <template #label>Target is not visible on small screens</template>
          <BaseToggle v-model="guidedTourStep.isTargetHiddenOnSmallScreens" />
        </BaseFormComponent>
      </TransitionCollapsible>

      <BaseFormComponent>
        <template #label>Next Step Trigger Element </template>
        <TourBuilderElementSelectorButton
          v-model="guidedTourStep.tiggerElementIdentifier"
        />
      </BaseFormComponent>
      <BaseFormComponent
        v-if="guidedTourStep.tiggerElementIdentifier != undefined"
      >
        <template #label>Indirect? </template>
        <BaseToggle v-model="guidedTourStep.isIndirectTrigger" />
      </BaseFormComponent>

      <BaseFormComponent>
        <template #label>Disabled Elements </template>
        <TourBuilderElementSelectorButton
          v-model="guidedTourStep.disableElementIdentifiers"
          :allow-multiple="true"
        />
      </BaseFormComponent>

      <div class="flex flex-col">
        <div class="flex flex-row items-center border-b py-2 mt-4 mb-2">
          <h2>Actions</h2>
          <BaseIconButton @click="addAction">
            <icon name="material-symbols:add-circle-outline" size="20" />
          </BaseIconButton>
        </div>
        <TourBuilderGuidedTourAction
          v-for="(action, index) in guidedTourStep.onNextActions ?? []"
          :key="index"
          :action="action"
          @remove="removeAction(index)"
          class="border-b last:border-none mb-2"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
