<script lang="ts" setup>
import { GuidedTour } from "classes/models/tours/guided-tour";

const tourBuilderStore = useTourBuilder();

const guidedTourStore = useGuidedTours();
const { allTours } = storeToRefs(guidedTourStore);

const startTour = (tour: GuidedTour) => {
  tourBuilderStore.isBuildingTour = false;

  useGuidedTours().startTour(tour, true);
};

const isRemoving = ref(false);
const removeTour = async (tour: GuidedTour) => {
  isRemoving.value = true;
  await tour.delete();
  isRemoving.value = false;
};
</script>

<template>
  <div class="fled flex-col h-full">
    <div class="flex flex-row items-center border-b p-2 mb-4">
      <h2 class="text-h5 mr-2">Guided Tours</h2>
      <BaseIconButton @click="tourBuilderStore.createTour()" :size="30">
        <icon name="material-symbols:add-circle-outline" size="20" />
      </BaseIconButton>
    </div>
    <div class="flex flex-col grow min-h-0 overflow-auto">
      <div
        v-for="tour in allTours"
        :key="tour.id"
        class="flex flex-row items-center justify-between hover:bg-light-default cursor-pointer border-b last:border-none p-4"
        @click="tourBuilderStore.editTour(tour)"
      >
        <div class="flex flex-row items-center">
          <BaseIconButton
            color="danger"
            @click.stop.prevent="removeTour(tour)"
            class="mr-2"
            :show-spinner="isRemoving"
          >
            <icon name="mdi:trash" />
          </BaseIconButton>
          <BaseIconButton @click.stop.prevent="startTour(tour)" class="mr-2">
            <icon name="mdi:play-circle-outline" />
          </BaseIconButton>
          <span>
            {{ tour.name }}
          </span>
        </div>
        <icon name="material-symbols:chevron-right-rounded" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
