<script lang="ts" setup>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

const props = defineProps({
  valueProp: {
    type: String,
    required: false,
    default: "value",
  },
  label: {
    type: String,
    required: false,
    default: "label",
  },
  searchable: {
    type: Boolean,
    required: false,
    default: false,
  },
  canClear: {
    type: Boolean,
    required: false,
    default: true,
  },
  mode: {
    type: String,
    default: "single",
    validator: (value: string) => ["single", "tags"].includes(value),
  },
  allowUserEntered: {
    type: Boolean,
    required: false,
    default: false,
  },
  noOptionsText: {
    type: String,
    required: false,
    default: "The list is empty.",
  },
  invisible: {
    type: Boolean,
    required: false,
    default: false,
  },
  groups: {
    type: Boolean,
    required: false,
    default: false,
  },
  fixedPositionOptions: {
    type: Boolean,
    required: false,
    default: false,
  },
});

// defineEmits(["keyup", "keydown"]);

const allowAbsent = ref(false);
const allowCreateOption = ref(false);
const createTag = ref(false);
const searchable = ref(props.searchable);
const addOptionOn = ref<string[]>([]);

if (props.allowUserEntered) {
  allowAbsent.value = true;
  createTag.value = true;
  allowCreateOption.value = true;
  searchable.value = true;
  addOptionOn.value = ["enter", "tab", ","];
}

// You can directly bind the :options property to <BaseSelect /> to pass an array of options
// The 'valueProp' parameter is where you specify the key that the value should be pulled from
// The 'label' parameter is where you specify the key that the label should be pulled from
// searchable will allow you to search the options
// canClear = false requires a value

// To create a select where a user can add their own options:
// - allow-absent="true"
// - create-option="true"
// - options="[]"
// - searchable="true"
const caret = ref(true);

const defaultClasses = computed(() => {
  let dropdownClasses = {};

  if (props.fixedPositionOptions) {
    dropdownClasses = {
      dropdown:
        "max-h-60 fixed -left-px -right-px bottom-0 transform translate-y-full border border-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b",
      dropdownTop:
        "-translate-y-full top-px bottom-auto rounded-b-none rounded-t",
      dropdownHidden: "hidden",
    };
  }

  if (props.invisible == true) {
    caret.value = false;
    return {
      container: "relative",
      containerActive: "",
      tags: "flex-grow flex-shrink flex flex-wrap items-center my-1 pl-1 rtl:pl-0 rtl:pr-1",
      tag: "flex flex-row bg-primary-default text-white rounded m-1 p-1",
      option: "p-1 hover:bg-primary-default hover:bg-opacity-20",
      optionPointed: " bg-primary-default bg-opacity-20",
      optionSelected: "bg-primary-default text-primary-on",
      groupLabel:
        "flex text-sm box-border items-center justify-start text-left font-bold cursor-default leading-normal",
      ...dropdownClasses,
    };
  }

  return {
    containerActive: "",
    tags: "flex-grow flex-shrink flex flex-wrap items-center my-1 pl-1 rtl:pl-0 rtl:pr-1",
    tag: "flex flex-row bg-primary-default text-white rounded m-1 p-1",
    option: "p-1 hover:bg-primary-default hover:bg-opacity-20",
    optionPointed: " bg-primary-default bg-opacity-20",
    optionSelected: "bg-primary-default text-primary-on",
    groupLabel:
      "flex text-sm box-border items-center justify-start text-left font-bold cursor-default leading-normal px-1 text-h5 py-1 border-b",
    ...dropdownClasses,
  };
});
</script>

<template>
  <Multiselect
    :caret="caret"
    :mode="mode ?? 'single'"
    :valueProp="valueProp"
    :label="label"
    :searchable="searchable"
    :canClear="canClear"
    :canDeselect="false"
    :allowAbsent="allowAbsent"
    :createOption="allowCreateOption"
    :addTagOn="addOptionOn"
    :noOptionsText="noOptionsText"
    style="margin: 0"
    :style="{
      'min-height': mode == 'tags' ? '50px' : '42px',
    }"
    :classes="defaultClasses"
    :groups="groups"
  >
    <template #singlelabel="{ value }">
      <slot name="singlelabel" :value="value"></slot>
    </template>
    <template #option="{ option }">
      <slot name="option" :option="option"></slot>
    </template>
  </Multiselect>
</template>

<style scoped></style>
