export const useDocumentParagraphStore = (
  documentId: string,
  paragraphId: string
) => {
  const { revision } = useSubmittedDocumentStore(documentId);

  if (!revision) {
    throw new Error("Revision is not defined");
  }

  return useDocumentParagraph(documentId, revision.id, paragraphId)();
};
