import { DBFactory } from "~/classes/database/db_factory";
import { BaseModel } from "../base.model";

export type Students = Student[];

export type StudentContact = {
  label: string;
  email: string;
};

type StudentConstrutorParams = {
  id?: string;
  externalId: string;
  externalProvider: "sakai" | "google" | "canvas" | "iew";
  name: string;
  userId: string;
  classroomIds: string[];
  classroomName: string;
  externalStudentData: { [key: string]: any };
  enableStyleOverrides: boolean;
  availableStyleCriteria?: string[];
  isArchived: boolean;
};
export class Student extends BaseModel {
  id?: string = "";
  externalId: string;
  externalProvider: "sakai" | "google" | "canvas" | "iew" = "iew";
  name: string = "";
  userId: string = "";
  classroomIds;
  classroomName: string = "";
  externalStudentData: { [key: string]: any } = {};
  enableStyleOverrides: boolean = false;
  availableStyleCriteria?: string[];
  isArchived: boolean = false;

  constructor({
    id,
    externalId,
    externalProvider,
    name,
    userId,
    classroomIds,
    classroomName,
    externalStudentData,
    enableStyleOverrides,
    availableStyleCriteria,
    isArchived,
  }: StudentConstrutorParams) {
    super();

    this.id = id;
    this.externalId = externalId;
    this.externalProvider = externalProvider;
    this.name = name;
    this.userId = userId;
    this.classroomIds = classroomIds;
    this.classroomName = classroomName;
    this.externalStudentData = externalStudentData;
    this.enableStyleOverrides = enableStyleOverrides;
    this.availableStyleCriteria = availableStyleCriteria;
    this.isArchived = isArchived;

    this.modelDatabaseConfig = {
      collection: "/students",
      path: `/students/${this.id}`,
    };
  }
  toMap(): ModelDatabaseData {
    return super.toMap();
  }

  static default(userId: string, classroomId: string, classroomName: string) {
    return new Student({
      name: "",
      userId: userId,
      classroomIds: [classroomId],
      classroomName: classroomName,
      externalId: "",
      externalProvider: "iew",
      externalStudentData: {},
      enableStyleOverrides: false,
      isArchived: false,
    });
  }

  static fromMap(map: any): Student {
    const instance = new Student(map);
    return instance;
  }

  get firstName(): string {
    return this.name.split(" ")[0];
  }

  save = async () => {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.modelDatabaseConfig);
  };
}
