import { useDocumentChecklist } from "@/stores/useDocumentChecklist";

export const useDocumentChecklistStore = (documentId: string) => {
  const { revision } = useSubmittedDocumentStore(documentId);

  if (!revision) {
    throw new Error("Revision is not defined");
  }

  return useDocumentChecklist(documentId, revision.id)();
};
