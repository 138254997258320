<script lang="ts" setup>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps({
  color: {
    type: String,
    default: "primary",
  },
  showSpinner: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    required: false,
  },
});

const dynamicStyles: any = {};

switch (props.color) {
  case "secondary":
    dynamicStyles[
      `active:bg-opacity-10 hover:bg-opacity-10 active:bg-secondary-tint hover:bg-secondary-shade text-secondary-default`
    ] = true;
    break;
  case "danger":
    dynamicStyles[
      `active:bg-opacity-10 hover:bg-opacity-10 active:bg-danger-tint hover:bg-danger-shade text-danger-default`
    ] = true;
    break;
  case "success":
    dynamicStyles[
      `active:bg-opacity-10 hover:bg-opacity-10 active:bg-success-tint hover:bg-success-shade text-success-default`
    ] = true;
    break;
  case "google":
    dynamicStyles[
      `active:bg-opacity-10 hover:bg-opacity-10 active:bg-google-tint hover:bg-google-shade text-google-default`
    ] = true;
    break;
  case "primary":
  default:
    dynamicStyles[
      `active:bg-opacity-10 hover:bg-opacity-10 active:bg-primary-tint hover:bg-primary-shade text-primary-default`
    ] = true;
    break;
}
</script>

<template>
  <div
    v-tooltip="tooltip"
    class="relative p-2 rounded cursor-pointer flex flex-row items-center justify-center select-none text-center"
    :class="dynamicStyles"
    :style="{
      'textIndent': '0px',
    }"
  >
    <ClientOnly>
      <slot />
      <div
        :class="{
          ' opacity-100 pointer-events-auto': showSpinner,
          ' opacity-0 pointer-events-none': showSpinner != true,
        }"
        class="flex rounded items-center justify-center absolute top-0 left-0 w-full h-full bg-overlay"
      >
        <PulseLoader :loading="true" color="white" size="8px"></PulseLoader>
      </div>
    </ClientOnly>
  </div>
</template>

<style scoped>
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 150ms ease-in;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}
</style>
