import { ParagraphPositionData } from "types/Checklist";

export const useSumParagraphData = (
  paragraphPositionsData: ParagraphPositionData[]
) => {
  return paragraphPositionsData.reduce((sum, item) => {
    let effectiveScore = 0;

    if (typeof item.manualScore === "number") {
      effectiveScore = item.manualScore;
    } else if (typeof item.score === "number") {
      effectiveScore = item.score;
    }

    return sum + effectiveScore;
  }, 0);
};
