import {
  CollectionReference,
  DocumentData,
  Firestore,
  Query,
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  deleteField,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { DBAbstract } from "./db_abstract";
// @ts-ignore
import { collectionData, docData } from "rxfire/firestore";
import { Observable, map } from "rxjs";
import { BaseModel } from "~/classes/models/base.model";

export class FirestoreClientDatabase extends DBAbstract {
  db: Firestore;

  constructor() {
    super();

    this.db = useFirestore();
  }

  generateId(databaseConfig: ModelDatabaseConfig): string {
    const collectinoReference = collection(this.db, databaseConfig.path);
    const docRef = doc(collectinoReference);
    return docRef.id;
  }

  async get(databaseConfig: ModelDatabaseConfig) {
    const docRef = doc(this.db, databaseConfig.path);
    const docSnapshot = await getDoc(docRef);

    if (!docSnapshot.data()) {
      throw new Error(`Document not found at path: ${databaseConfig.path}`);
    }

    return {
      ...docSnapshot.data(),
      id: docSnapshot.id,
    } as ModelDatabaseData;
  }

  async save(
    data: ModelDatabaseData,
    config: ModelDatabaseConfig,
    merge: boolean = true
  ): Promise<string> {
    if (!data.id) {
      const docRef = collection(this.db, config.collection);
      const response = await addDoc(docRef, data);
      return response.id;
    } else {
      const docRef = doc(this.db, config.path);

      for (const key in data) {
        if (data[key] == undefined) {
          data[key] = deleteField();
        }
      }

      const copiedData = { ...data };
      delete copiedData.id;

      await setDoc(docRef, copiedData, { merge: merge });

      return data.id;
    }
  }

  async delete(databaseConfig: ModelDatabaseConfig) {
    const docRef = doc(this.db, databaseConfig.path);
    await deleteDoc(docRef);
  }

  async list(
    databaseConfig: ModelDatabaseConfig,
    queryData?: ModelQueryConfig
  ): Promise<ModelDatabaseData[]> {
    const collectionReference:
      | CollectionReference<DocumentData>
      | Query<DocumentData> = databaseConfig.collectionGroup
      ? collectionGroup(this.db, databaseConfig.collectionGroup)
      : collection(this.db, databaseConfig.path);

    const queryReference = this.buildQuery(collectionReference, queryData);

    const querySnapshot = await getDocs(queryReference ?? collectionReference);

    const data = querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      } as ModelDatabaseData;
    });

    return data;
  }

  streamRecord(
    databaseConfig: ModelDatabaseConfig
  ): Observable<ModelDatabaseData | undefined> {
    const docRef = doc(this.db, databaseConfig.path);

    return docData(docRef, { idField: "id" }).pipe(
      map((docSnapshot) => {
        if (!docSnapshot) {
          return undefined;
        }

        return docSnapshot as ModelDatabaseData;
      })
    );
  }

  streamList(
    databaseConfig: ModelDatabaseConfig,
    queryData?: ModelQueryConfig | undefined
  ): Observable<ModelDatabaseData[]> {
    const collectionReference:
      | CollectionReference<DocumentData>
      | Query<DocumentData> = databaseConfig.collectionGroup
      ? collectionGroup(this.db, databaseConfig.collectionGroup)
      : collection(this.db, databaseConfig.path);

    const queryReference = this.buildQuery(collectionReference, queryData);

    return collectionData(queryReference ?? collectionReference, {
      idField: "id",
    }).pipe(
      map((docs: any) => docs.map((doc: any) => doc as ModelDatabaseData))
    );
  }

  private buildQuery(
    collectinoReference:
      | CollectionReference<DocumentData>
      | Query<DocumentData>,
    queryData?: ModelQueryConfig
  ) {
    let q = undefined;

    if (queryData) {
      for (const queryInstance of queryData) {
        switch (queryInstance.type) {
          case "where":
            if (
              queryInstance.field == undefined ||
              queryInstance.operator == undefined ||
              queryInstance.value == undefined
            )
              continue;

            q = query(
              q ?? collectinoReference,
              where(
                queryInstance.field,
                queryInstance.operator,
                queryInstance.value
              )
            );
            break;
          case "orderBy":
            if (
              queryInstance.field == undefined ||
              queryInstance.direction == undefined
            )
              continue;

            q = query(
              q ?? collectinoReference,
              orderBy(queryInstance.field!, queryInstance.direction!)
            );
            break;
          case "limit":
            if (queryInstance.value == undefined) continue;

            q = query(q ?? collectinoReference, limit(queryInstance.value));
            break;
        }
      }
    }

    return q ?? collectinoReference;
  }

  async batchUpdate(models: BaseModel[]) {
    const batch = writeBatch(this.db);

    models.forEach((item) => {
      if (item.toMap().id) {
        const docRef = doc(
          this.db,
          `${item.pathPrefixOverride ?? ""}` + item.databaseConfig.path
        );
        batch.set(docRef, item.toMap(), { merge: true });
      } else {
        const docRef = doc(
          collection(
            this.db,
            `${item.pathPrefixOverride ?? ""}` + item.databaseConfig.collection
          )
        );
        batch.set(docRef, item.toMap());
      }
    });

    await batch.commit();
  }

  async batchDelete(models: BaseModel[]) {
    const batch = writeBatch(this.db);

    models.forEach((item) => {
      const docRef = doc(this.db, item.databaseConfig.path);
      batch.delete(docRef);
    });

    await batch.commit();
  }
}
