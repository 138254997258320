<script lang="ts" setup>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps({
  color: {
    type: String,
    default: "primary",
  },
  showSpinner: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  size: {
    type: Number,
    default: 40,
  },
});

const dynamicStyles: any = {};

switch (props.color) {
  case "secondary":
    dynamicStyles[
      `bg-secondary-default hover:bg-secondary-shade text-secondary-on`
    ] = true;
    break;
  case "google":
    dynamicStyles[`bg-google-default hover:bg-google-shade text-google-on`] =
      true;
    break;
  case "danger":
    dynamicStyles[`bg-danger-default hover:bg-danger-shade text-danger-on`] =
      true;
    break;
  case "success":
    dynamicStyles[`bg-success-default hover:bg-success-shade text-success-on`] =
      true;
    break;
  case "primary":
  default:
    dynamicStyles[`bg-primary-default hover:bg-primary-shade text-primary-on`] =
      true;
    break;
}
</script>

<template>
  <div
    class="relative rounded-full cursor-pointer overflow-hidden shadow-lg"
    :style="{
      width: `${size}px`,
      height: `${size}px`,
    }"
  >
    <button
      class="p-2 w-full rounded-full select-none h-full flex flex-col items-center justify-center"
      :class="{
        ...dynamicStyles,
        '!text-medium-tint !bg-light-default ': disabled,
        ' !active:bg-opacity-20': disabled != true,
      }"
    >
      <slot />
    </button>
    <transition>
      <div
        v-if="showSpinner"
        class="flex rounded items-center justify-center absolute top-0 left-0 w-full h-full bg-overlay text-primary-on"
      >
        <icon
          name="svg-spinners:ring-resize"
          class="text-primary-on"
          :size="`${size - 15}`"
        />
      </div>
    </transition>
  </div>
</template>

<style scoped>
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 150ms ease-in;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}
</style>
