export const useGuardRouteByPermission = (
  permission: string,
  options?: { allowSysAdmin?: boolean; allowAdmin?: boolean }
) => {
  return defineNuxtRouteMiddleware((to, from) => {
    if (process.server) return;

    if (useAppUserData().hasPermission(permission, options) !== true) {
      return navigateTo("/", { replace: true });
    }
  });
};
