<script lang="ts" setup>
const enter = (element: any) => {
  const width = getComputedStyle(element).width;

  element.style.width = width;
  element.style.position = "absolute";
  element.style.visibility = "hidden";
  element.style.height = "auto";

  const height = getComputedStyle(element).height;

  element.style.width = null;
  element.style.position = null;
  element.style.visibility = null;
  element.style.height = 0;

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height;
  });
};

const afterEnter = (element: any) => {
  element.style.height = "auto";
};

const leave = (element: any) => {
  const height = getComputedStyle(element).height;

  element.style.height = height;

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = 0;
  });
};
</script>

<template>
  <Transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </Transition>
</template>

<style scoped>
.expand-enter-to,
.expand-leave-from {
  opacity: 1;
}

.expand-enter-active,
.expand-leave-active {
  transition: all 250ms ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  opacity: 0;
  height: 0;
}
</style>
