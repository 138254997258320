import { library, config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEyeSlash,
  faEye,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faWandSparkles,
  faXmark,
  faX,
  faCheck,
  faFileArrowDown,
  faItalic,
  faBold,
  faStrikethrough,
  faUnderline,
  faCircle,
  faHashtag,
  faBan,
  faBars,
  faArrowsLeftRight,
  faParagraph,
  // faSlashForward,
  faComment,
  faPenToSquare,
  faMicrophone,
  faStop,
  faSpinner,
  faRotateLeft,
  faRotateRight,
  faArrowUpRightFromSquare,
} from "@fortawesome/free-solid-svg-icons";

import { faGoogle } from "@fortawesome/free-brands-svg-icons";

library.add(
  faEyeSlash,
  faEye,
  faGoogle,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faChevronRight,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faWandSparkles,
  faXmark,
  faX,
  faCheck,
  faFileArrowDown,
  faItalic,
  faBold,
  faStrikethrough,
  faUnderline,
  faCircle,
  faHashtag,
  faBan,
  faBars,
  faArrowsLeftRight,
  faParagraph,
  faComment,
  faPenToSquare,
  faMicrophone,
  faStop,
  faSpinner,
  faRotateLeft,
  faRotateRight,
  faArrowUpRightFromSquare
  // faSlashForward
);

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false;

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component("font-awesome-icon", FontAwesomeIcon, {});
});
