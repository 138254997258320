import { DBFactory } from "classes/database/db_factory";
import { GoogleOauthToken } from "./google-oauth-token.model";
import { map, Observable } from "rxjs";

export class GoogleOauthService {
  static async get(userId: string) {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      path: `/googleAuthTokens/${userId}`,
      collection: `/googleAuthTokens`,
    });

    return GoogleOauthToken.fromMap(data);
  }

  static streamRecord(
    userId: string
  ): Observable<GoogleOauthToken | undefined> {
    const db = DBFactory.createDatabase();
    return db
      .streamRecord({
        path: `/googleAuthTokens/${userId}`,
        collection: `/googleAuthTokens`,
      })
      .pipe(
        map((data) => {
          if (!data) return undefined;

          return GoogleOauthToken.fromMap(data);
        })
      );
  }
}
