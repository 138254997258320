import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "../base.model";
import { GoogleAPIScope } from "~/types/enums/GoogleAPIScopes.enum";

type GoogleOauthTokenConstructorParams = {
  userId: string;
  scope: GoogleAPIScope[];
  accessToken: string;
  expiryDate: number;
  refreshToken: string;
  bearer: string;
};

export class GoogleOauthToken extends BaseModel {
  userId: string;
  scope: GoogleAPIScope[];
  accessToken: string;
  expiryDate: number;
  refreshToken: string;
  bearer: string;

  constructor(params: GoogleOauthTokenConstructorParams) {
    super(params);
    this.userId = params.userId;
    this.scope = params.scope;
    this.accessToken = params.accessToken;
    this.expiryDate = params.expiryDate;
    this.refreshToken = params.refreshToken;
    this.bearer = params.bearer;
  }

  toMap(): ModelDatabaseData {
    return {
      id: this.userId,
      userId: this.userId,
      scope: this.scope.join(" "),
      accessToken: this.accessToken,
      expiryDate: this.expiryDate,
      refreshToken: this.refreshToken,
      bearer: this.bearer,
    };
  }

  static fromMap(map: any) {

    if(map.scope) {
      map.scope = map.scope.split(" ");
    }

    return new GoogleOauthToken(map);
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/googleAuthTokens`,
      path: `/googleAuthTokens/${this.userId}`,
    };
  }

  async save() {
    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.databaseConfig);
  }

  async delete() {
    const db = DBFactory.createDatabase();
    await db.delete(this.databaseConfig);
  }

  hasScope(scope: GoogleAPIScope) {
    return this.scope.includes(scope);
  }

  addScope(scope: GoogleAPIScope) {
    if (!this.hasScope(scope)) {
      this.scope.push(scope);
    }
  }

  addScopes(scopes: GoogleAPIScope[]) {
    this.scope = [...new Set([...this.scope, ...scopes])];
  }
}
