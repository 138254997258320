import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getFirestore, initializeFirestore } from "firebase/firestore";

export default defineNuxtPlugin((nuxtApp) => {
  const firebaseApp = initializeApp(
    {
      apiKey: "AIzaSyDxTUqbou9DNf_RNWHCEHk7-O8u18a2rtg",
      authDomain: "scribekick-cb492.firebaseapp.com",
      projectId: "scribekick-cb492",
      storageBucket: "scribekick-cb492.appspot.com",
      messagingSenderId: "166612580846",
      appId: "1:166612580846:web:fe640b969d50ea6251b6ff",
      measurementId: "G-JWE5QKH3H1",
    },
    "iew-gradebook"
  );

  initializeFirestore(firebaseApp, {
    ignoreUndefinedProperties: true,
  });

  const functions = getFunctions(firebaseApp);
  const firestore = getFirestore(firebaseApp);

  //   export { firebaseApp, functions, firestore };
});
