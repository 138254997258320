import { AnnotationTypes } from "~/types/enums/AnnotationTypes.enum";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  query,
  where,
  Unsubscribe,
} from "firebase/firestore";
import { AnnotationType } from "types/AnnotationType";
import { useSystemCommentsStore } from "~/composables/useSystemCommentsStore";

export const useAnnotationTypes = () =>
  definePiniaStore(`/annotation/types}`, () => {
    const annotationTypes = ref<AnnotationType[]>([]);
    const annotationTypesSubscription = ref<Unsubscribe | undefined>();

    const isInitialized = ref(false);
    const initializeAnnotationTypes = async () => {
      if (isInitialized.value) return;

      const db = useFirestore();
      const annotationTypesCollectionRef = collection(db, "annotationTypes");

      if (annotationTypesSubscription.value) {
        annotationTypesSubscription.value();
      }

      annotationTypesSubscription.value = onSnapshot(
        annotationTypesCollectionRef,
        (snapshot) => {
          annotationTypes.value = snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              value: doc.id,
            } as AnnotationType;
          });

          annotationTypes.value.sort((a, b) => {
            return a.displayOrder - b.displayOrder;
          });
        }
      );

      isInitialized.value = true;
    };

    const fetchAnnotationType = async (value: string) => {
      const db = useFirestore();
      const annotationTypesCollectionRef = collection(db, "annotationTypes");
      const docRef = doc(annotationTypesCollectionRef, value);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data() as AnnotationType;
      } else {
        return undefined;
      }
    };

    const defaultAnnotationType = () => {
      return {
        value: AnnotationTypes.commentOnly,
        showDefaultSymbol: false,
        displayOrder: 0,
        commentsTag: "annotation-",
      } as AnnotationType;
    };

    const saveAnnotationType = async (annotationType: AnnotationType) => {
      const db = useFirestore();
      const annotationTypesCollectionRef = collection(db, "annotationTypes");
      const docRef = doc(annotationTypesCollectionRef, annotationType.value);
      await setDoc(docRef, annotationType);
    };

    const annotationTypeComments = computed(() => {
      return (
        annotationType: AnnotationType,
        documentId?: string
      ): QuickComments => {
        const sytemCommentsStore = useSystemCommentsStore();
        const { annotationTypeComments: annotationTypeCommentByTag } =
          storeToRefs(sytemCommentsStore);

        if (annotationType.commentsTag === undefined) return [];

        return annotationTypeCommentByTag.value(
          annotationType.commentsTag,
          documentId
        );
      };
    });

    const annotationTypeFromType = (annotationType: AnnotationTypes) => {
      if (annotationType == AnnotationTypes.commentOnly) {
        return {
          value: AnnotationTypes.commentOnly,
          icon: "material-symbols:comment",
          showDefaultSymbol: false,
          displayOrder: 0,
          hint: "Open Comment",
        };
      }

      return annotationTypes.value.find((type) => {
        return type.value === annotationType;
      });
    };

    return {
      initializeAnnotationTypes,
      saveAnnotationType,
      fetchAnnotationType,
      defaultAnnotationType,
      annotationTypeFromType,
      annotationTypes,
      annotationTypeComments,
    };
  });
