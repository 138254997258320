export enum DocumentState {
  notImported = "notImported",
  converting = "converting",
  evaluating = "evaluating",
  processing = "processing",
  conversionFailed = "conversionFailed",
  pending = "pending",
  uploading = "uploading",
  missing = "missing",
  draft = "draft",
  archived = "archived",
  ignored = "ignored",
  studentReview = "studentReview",
  submitted = "submitted",
  grading = "grading",
  graded = "graded",
  googlePermissionError = "googlePermissionError",
  unsupportedFormat = "unsupportedFormat",
}
