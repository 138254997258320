import { GuidedTour } from "classes/models/tours/guided-tour";

export const useTourBuilder = defineStore("/tour/builder", () => {
  const isBuildingTour = useLocalStorage<boolean>("is-building-tour", false);
  const isSelectingElement = useLocalStorage<boolean>(
    "is-selecting-element",
    false
  );

  const guidedTour = ref<GuidedTour | undefined>(undefined);

  const createTour = () => {
    guidedTour.value = new GuidedTour({
      name: "New Tour",
      steps: [],
    });
  };

  const editTour = (tour?: GuidedTour) => {
    guidedTour.value = tour;
  };

  return {
    isBuildingTour,
    isSelectingElement,

    guidedTour,
    createTour,
    editTour,
  };
});
