import { EvaluativeParagraphSection } from "~/types/enums/EvaluativeParagraphSection";

import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  writeBatch,
} from "firebase/firestore";
import {
  EvaluativeParagraph,
  EvaluativeParagraphs,
} from "types/EvaluativeParagraph";

export const useDocumentEvaluativeParagraphs = (
  documentId: string,
  revisionId: string
) =>
  defineStore(
    `/documents/${documentId}/revisions/${revisionId}/evaluativeParagraphs`,
    () => {
      const allEvaluativeParagraphs = ref<EvaluativeParagraphs>([]);
      const evaluativeParagraphIdToPositionMap = ref<{ [key: string]: number }>(
        {}
      );

      const mapParagraphPositionToBodyParagraphPosition = ref<{
        [key: number]: number;
      }>({});

      const evaluativeParagraphsSubscription = ref<Unsubscribe | undefined>();

      const streamEvaluativeParagraphs = () => {
        const db = useFirestore();
        const evaluativeParagraphsRef = collection(
          db,
          `/documents/${documentId}/revisions/${revisionId}/evaluativeParagraphs`
        );

        const evaluativeParagraphsQuery = query(
          evaluativeParagraphsRef,
          orderBy("index")
        );

        if (evaluativeParagraphsSubscription.value) {
          evaluativeParagraphsSubscription.value();
        }

        evaluativeParagraphsSubscription.value = onSnapshot(
          evaluativeParagraphsQuery,
          (snapshot) => {
            allEvaluativeParagraphs.value = snapshot.docs.map((doc) => {
              const evaluativeParagraph = {
                ...doc.data(),
                id: doc.id,
              } as EvaluativeParagraph;

              evaluativeParagraphIdToPositionMap.value[evaluativeParagraph.id] =
                evaluativeParagraph.position;

              return evaluativeParagraph;
            });
          }
        );
      };

      const bodyParagraphs = computed(() => {
        return allEvaluativeParagraphs.value.filter(
          (evaluativeParagraph) =>
            evaluativeParagraph.section === EvaluativeParagraphSection.body &&
            evaluativeParagraph.startIndex < evaluativeParagraph.endIndex
        );
      });

      const numBodyParagraphs = computed(() => {
        return bodyParagraphs.value.length;
      });

      const bodyIndexFromAllIndex = computed(() => {
        return (index: number) => {
          return bodyParagraphs.value.findIndex((p) => p.index == index);
        };
      });

      //   Helpers
      const getEvaluativeParagraphByTokenIndex = computed(() => {
        return (tokenIndex: number) => {
          return allEvaluativeParagraphs.value.find((evaluativeParagraph) => {
            return (
              tokenIndex >= evaluativeParagraph.startIndex &&
              tokenIndex <= evaluativeParagraph.endIndex
            );
          });
        };
      });

      const tokenIdPositionMapStore =
        useDocumentTokenIdPositionMapStore(documentId);
      const { tokenGlobalPositionFromLocalIndex } = storeToRefs(
        tokenIdPositionMapStore
      );

      const getParagraphIdFromParagraphPosition = computed(() => {
        return (paragraphPosition: number) => {
          const evaluativeParagraph =
            bodyParagraphs.value[paragraphPosition - 1];

          if (evaluativeParagraph == undefined) return undefined;

          const tokenEndIndex = evaluativeParagraph.endIndex;

          // Need to convert the token end index to a global position
          const globalPosition =
            tokenGlobalPositionFromLocalIndex.value(tokenEndIndex);

          const tokenStore = useParagraphTokenStore(documentId, globalPosition);
          return tokenStore.paragraphId;
        };
      });

      const changeEvaluativeParagraphSection = async (
        evalutiveParagraph: EvaluativeParagraph,
        section: EvaluativeParagraphSection
      ) => {
        evalutiveParagraph.section = section;
        await saveEvaluativeParagraph(evalutiveParagraph);
      };

      const paragraphPositionFromBodyParagraphPosition = computed(() => {
        return (bodyParagraphPosition: number) => {
          return bodyParagraphs.value[bodyParagraphPosition - 1].position;
        };
      });

      const changeEvaluativeParagraphsSection = async (
        evaluativeParagraphs: EvaluativeParagraphs,
        section: EvaluativeParagraphSection
      ) => {
        const db = useFirestore();
        const batch = writeBatch(db);

        for (const evaluativeParagraph of evaluativeParagraphs) {
          evaluativeParagraph.section = section;
          const evaluativeParagraphRef = doc(
            db,
            `/documents/${documentId}/revisions/${revisionId}/evaluativeParagraphs/${evaluativeParagraph.id}`
          );
          batch.update(evaluativeParagraphRef, evaluativeParagraph);
        }

        await batch.commit();
      };

      const mapBodyParagraphToParagraphPosition = (
        bodyParagraphPosition: number,
        paragraphPosition: number
      ) => {
        mapParagraphPositionToBodyParagraphPosition.value[paragraphPosition] =
          bodyParagraphPosition;
      };

      const saveEvaluativeParagraph = async (
        evaluativeParagraph: EvaluativeParagraph
      ) => {
        const db = useFirestore();
        const evaluativeParagraphRef = doc(
          db,
          `/documents/${documentId}/revisions/${revisionId}/evaluativeParagraphs/${evaluativeParagraph.id}`
        );

        await setDoc(evaluativeParagraphRef, evaluativeParagraph);
      };

      //   Merge Functions

      const evaluativeParagraphsToMerge = ref<EvaluativeParagraphs>([]);
      const isMergingEvaluativeParagraphs = computed(() => {
        return evaluativeParagraphsToMerge.value.length > 0;
      });

      const toggleEvaluativeParagraphToMergeByToken = (tokenIndex: number) => {
        const evaluativeParagraph =
          getEvaluativeParagraphByTokenIndex.value(tokenIndex);

        if (!evaluativeParagraph) return;

        const isMerging =
          isEvaluativeParagraphToBeMerged.value(evaluativeParagraph);

        if (isMerging == true) {
          evaluativeParagraphsToMerge.value =
            evaluativeParagraphsToMerge.value.filter(
              (p) => p.id != evaluativeParagraph.id
            );
        } else {
          evaluativeParagraphsToMerge.value = [
            ...evaluativeParagraphsToMerge.value,
            evaluativeParagraph,
          ];
        }
      };

      const isEvaluativeParagraphToBeMerged = computed(() => {
        return (evaluativeParagraph: EvaluativeParagraph) => {
          return (
            evaluativeParagraphsToMerge.value.findIndex(
              (p) => p.id == evaluativeParagraph.id
            ) >= 0
          );
        };
      });

      const splitEvaluativeParagraph = async ({
        positionToSplit,
        evaluativeParagraphToSplit,
      }: {
        positionToSplit: number;
        evaluativeParagraphToSplit: EvaluativeParagraph;
      }) => {
        const matchingElement = evaluativeParagraphToSplit.elements.find(
          (element) =>
            element.startIndex <= positionToSplit &&
            element.endIndex >= positionToSplit
        );

        if (matchingElement == undefined) return;

        // You can't splut something right at the end or start of an evaluative paragrpah. Has to be in the middle.
        if (
          matchingElement.startIndex == positionToSplit ||
          matchingElement.endIndex == positionToSplit
        ) {
          useBaseToast("You can't split here.", "error");
          return;
        }

        const endElementIndex = matchingElement.endIndex;
        const endIndex = evaluativeParagraphToSplit.endIndex;

        // Assign a new end index to the matchign endIndex
        matchingElement.endIndex = positionToSplit - 1;
        evaluativeParagraphToSplit.endIndex = positionToSplit - 1;

        // Next we need to get the index of the element that we're splitting:
        const elementIndex = evaluativeParagraphToSplit.elements.findIndex(
          (element) => element.startIndex == matchingElement.startIndex
        );

        // Next we need to plucl any elements that come after this index and store them in a new array:
        const elementsAfterSplit = evaluativeParagraphToSplit.elements.slice(
          elementIndex + 1
        );

        const newElement = {
          startIndex: positionToSplit,
          endIndex: endElementIndex,
          type: matchingElement.type,
        } as EvaluativeParagraphElement;

        const newElements = [newElement, ...elementsAfterSplit];

        const db = useFirestore();

        // Next we need to create a new evaluative paragraph that star
        const newEvaluativeParagraph = {
          elements: newElements,
          startIndex: positionToSplit,
          endIndex,
          position: evaluativeParagraphToSplit.position + 1,
          index: evaluativeParagraphToSplit.index + 1,
          section: "body",
          text: "",
          sectionSubType: "",
          id: doc(
            collection(
              db,
              `/documents/${documentId}/revisions/${revisionId}/evaluativeParagraphs`
            )
          ).id,
        } as EvaluativeParagraph;

        // Get the index of the evaluative paragraph to split from allEvaluativeParagraphs
        const splitIndex = allEvaluativeParagraphs.value.findIndex(
          (p) => p.id == evaluativeParagraphToSplit.id
        );

        // Insert the new evaluative paragraph into the allEvaluativeParagraphs array at the index
        allEvaluativeParagraphs.value.splice(
          splitIndex + 1,
          0,
          newEvaluativeParagraph
        );

        let index = 0;

        const batch = writeBatch(db);

        for (const paragraph of allEvaluativeParagraphs.value) {
          paragraph.index = index;
          paragraph.position = index + 1;

          const paragraphRef = doc(
            db,
            `/documents/${documentId}/revisions/${revisionId}/evaluativeParagraphs/${paragraph.id}`
          );

          batch.set(paragraphRef, paragraph, { merge: true });

          index++;
        }

        await batch.commit();
      };

      const mergeEvaluativeParagraphs = async (
        evaluativeParagraphs: EvaluativeParagraphs
      ) => {
        // If we have less than two paragraphs to merge then there's really nothing to merge
        if (evaluativeParagraphs.length < 2) return;

        evaluativeParagraphs.sort((a, b) => {
          return a.index - b.index;
        });

        // Pop the first evaluative paragraph from the list
        const firstEvaluativeParagraph = evaluativeParagraphs.shift();

        if (!firstEvaluativeParagraph) return;

        // Merge the rest of the paragraphs into the first paragraph by appending the elements

        const db = useFirestore();
        const batch = writeBatch(db);

        for (const evaluativeParagraph of evaluativeParagraphs) {
          firstEvaluativeParagraph.elements = [
            ...firstEvaluativeParagraph.elements,
            ...evaluativeParagraph.elements,
          ];

          const evaluativeParagraphRef = doc(
            db,
            `/documents/${documentId}/revisions/${revisionId}/evaluativeParagraphs/${evaluativeParagraph.id}`
          );

          batch.delete(evaluativeParagraphRef);
        }

        // Update the start and end index of the first evaluative paragraph
        for (const element of firstEvaluativeParagraph.elements) {
          if (element.startIndex < firstEvaluativeParagraph.startIndex) {
            firstEvaluativeParagraph.startIndex = element.startIndex;
          }

          if (element.endIndex > firstEvaluativeParagraph.endIndex) {
            firstEvaluativeParagraph.endIndex = element.endIndex;
          }
        }

        // Update the first evaluative paragraph and delete the others

        const firstEvaluativeParagraphRef = doc(
          db,
          `/documents/${documentId}/revisions/${revisionId}/evaluativeParagraphs/${firstEvaluativeParagraph.id}`
        );

        batch.update(firstEvaluativeParagraphRef, {
          ...firstEvaluativeParagraph,
          section: "body",
        });

        await batch.commit();
        useDocumentChecklistStore(documentId).reinferAllCriteria();
        stopMerging();
      };

      const stopMerging = () => {
        evaluativeParagraphsToMerge.value = [];
      };

      const relevantInstanceToParagraphDataMap = ref<{
        [key: string]: {
          paragraphId: string;
          position: number;
        };
      }>({});

      const getParagraphIdForInstance = (
        relevantInstance: EvaluationRelevantInstance
      ) => {
        const relevantInstanceId = relevantInstance.uid;

        const tokens = relevantInstance.tokens;

        if (tokens.length == 0) return;

        const startToken = tokens[0];

        if (!startToken) return;

        const startTokenId = startToken.id;

        if (!startTokenId) return;

        const startTokenPosition =
          useDocumentTokenIdPositionMapStore(
            documentId
          ).tokenGlobalPositionFromId(startTokenId);

        if (!startTokenPosition) return;

        const startTokenStore = useParagraphTokenStore(
          documentId,
          startTokenPosition
        );

        if (!startTokenStore) return;

        const paragraphId = startTokenStore.paragraphId;

        return paragraphId;
      };

      // Functons for mapping evaluative paragrapsh to body paragraph positions
      const assignRelevantInstanceToParagraphPosition = (
        relevantInstance: EvaluationRelevantInstance,
        paragraphPosition: number | undefined
      ) => {
        const paragraphId = getParagraphIdForInstance(relevantInstance);

        if (!paragraphId) return;

        if (paragraphPosition) {
          relevantInstanceToParagraphDataMap.value[relevantInstance.uid] = {
            paragraphId,
            position: paragraphPosition,
          };
        } else {
          delete relevantInstanceToParagraphDataMap.value[relevantInstance.uid];
        }
      };

      const paragraphIdToPositionsMap = computed(() => {
        const paragraphIdToPositionsMap: { [key: string]: number[] } = {};
        const positionsData = Object.values(
          relevantInstanceToParagraphDataMap.value
        );

        for (const data of positionsData) {
          if (!paragraphIdToPositionsMap[data.paragraphId]) {
            paragraphIdToPositionsMap[data.paragraphId] = [];
          }

          paragraphIdToPositionsMap[data.paragraphId].push(data.position);

          // Remove duplicates
          paragraphIdToPositionsMap[data.paragraphId] = [
            ...new Set(paragraphIdToPositionsMap[data.paragraphId]),
          ];
        }

        return paragraphIdToPositionsMap;
      });

      const getPositionForRelevantInstance = (
        relevantInstance: EvaluationRelevantInstance
      ) => {
        const paragraphId = getParagraphIdForInstance(relevantInstance);

        if (!paragraphId) return undefined;

        const positions = paragraphIdToPositionsMap.value[paragraphId];

        if (!positions) return undefined;

        if (positions.length == 0) return undefined;

        return positions[0];
      };

      return {
        bodyParagraphs,
        numBodyParagraphs,
        bodyIndexFromAllIndex,
        evaluativeParagraphIdToPositionMap,
        streamEvaluativeParagraphs,
        changeEvaluativeParagraphSection,
        changeEvaluativeParagraphsSection,
        saveEvaluativeParagraph,

        // Helpers
        getEvaluativeParagraphByTokenIndex,
        getParagraphIdFromParagraphPosition,
        paragraphPositionFromBodyParagraphPosition,
        mapParagraphPositionToBodyParagraphPosition,
        mapBodyParagraphToParagraphPosition,

        // Merge functions
        evaluativeParagraphsToMerge,
        isMergingEvaluativeParagraphs,
        toggleEvaluativeParagraphToMergeByToken,
        mergeEvaluativeParagraphs,

        // Split functions
        splitEvaluativeParagraph,

        // Inference functions
        relevantInstanceToParagraphDataMap,
        assignRelevantInstanceToParagraphPosition,
        getPositionForRelevantInstance,
        getParagraphIdForInstance,
      };
    }
  );
