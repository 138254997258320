export const useCurrentUID = () => {
  const pseudoLoginStore = usePseudoLogin();

  if (pseudoLoginStore.isPseduLogin == true) {
    return pseudoLoginStore.pseudoId;
  }

  const user = useCurrentUser();

  if(user.value?.uid == undefined && useAppUserData().isLoggedIn != true) {
    return "-";
  }


  return user.value?.uid;
};
