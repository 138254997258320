<script lang="ts" setup></script>

<template>
  <div class="flex flex-col justify-center items-center p-8 w-screen h-screen">
    <img src="/img/logo.png" class="my-4 w-[250px]" />
    <span class="text-[22px] text-center">
      IEW Gradebook is designed for laptops and desktops and does not support
      mobile devices.
    </span>
  </div>
</template>

<style scoped></style>
