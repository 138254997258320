import { writeBatch } from "firebase/firestore";
import { stat } from "fs";
import {
  DocumentHistoryAnnotationState,
  DocumentHistoryState,
} from "types/DocumentHistoryState";
import { Paragraph, Paragraphs } from "types/Paragraph";
import {
  RevisionAnnotation,
  RevisionAnnotations,
} from "types/RevisionAnnotation";

export const useDocumentHistory = (documentId: string, revisionId: string) =>
  definePiniaStore(
    `/document/${documentId}/revisions/${revisionId}/history`,
    () => {
      const currentStateIndex = ref<number>(0);

      const initialState = {
        paragraphs: {},
        annotationState: {
          updatedAnnotations: {},
          allActiveAnnotations: [],
        },
      } as DocumentHistoryState;

      const states = ref<string[]>([JSON.stringify(initialState)]);

      const isParagraphsInitialized = ref<boolean>(false);
      const isAnnotationsInitialized = ref<boolean>(false);

      const canUndo = computed(() => {
        return currentStateIndex.value > 0 && states.value.length > 0;
      });
      const canRedo = computed(() => {
        return currentStateIndex.value < states.value.length - 1;
      });

      const isRestoringState = ref<boolean>(false);
      const shouldIgnoreNextStateChange = ref<boolean>(false);

      //   We're going to watch for changes on the paragraphs and annotations objects and use that to automatically trigger new states

      //   Here is where we have the real state managemnet
      // do we want to serialize and unseria

      const currentState = computed(() => {
        var serializedState = states.value[currentStateIndex.value];
        return unSerializeState(serializedState);
      });

      // First we serialize the state
      // Then we push that state onto the current state array
      // Then we increment the current state index
      const saveParagraphsState = (paragraphs: Paragraphs) => {
        const currentStateData = currentState.value;
        const currentStateParagraphs = currentStateData.paragraphs;
        const newStateParagraphs = {} as { [key: string]: Paragraph };
        for (var paragraph of paragraphs) {
          newStateParagraphs[paragraph.id!] = paragraph;

          if (currentStateParagraphs[paragraph.id!] != undefined) continue;

          var paragraphStore = useDocumentParagraphStore(
            documentId,
            paragraph.id
          );
          var lastParagraph = paragraphStore.lastSavedParagraph;
          if (lastParagraph == undefined) continue;

          currentStateParagraphs[paragraph.id!] = JSON.parse(
            lastParagraph
          ) as Paragraph;
        }

        currentStateData.paragraphs = currentStateParagraphs;

        states.value[currentStateIndex.value] =
          JSON.stringify(currentStateData);

        var state = {
          annotationState: {
            updatedAnnotations: {},
            allActiveAnnotations:
              useDocumentAnnotationsStore(documentId).allAnnotations,
          },
          paragraphs: newStateParagraphs,
        } as DocumentHistoryState;

        saveState(state);

        isParagraphsInitialized.value = true;
      };

      const saveAnnotationsState = (
        annotations: RevisionAnnotations,
        allAnnotations: RevisionAnnotations
      ) => {
        const currentStateData = currentState.value;
        const currentStateAnnotations =
          currentStateData.annotationState.updatedAnnotations;
        const newStateAnnotations = {} as { [key: string]: RevisionAnnotation };

        for (var annotation of annotations) {
          newStateAnnotations[annotation.id!] = annotation;

          if (currentStateAnnotations[annotation.id!] != undefined) continue;

          var lastAnnotation = useDocumentAnnotationsStore(
            documentId
          ).lastAnnotationState(annotation.id!) as RevisionAnnotation;
          if (lastAnnotation == undefined) continue;

          currentStateAnnotations[annotation.id!] = lastAnnotation;
        }

        currentStateData.annotationState.updatedAnnotations =
          currentStateAnnotations;

        states.value[currentStateIndex.value] =
          JSON.stringify(currentStateData);

        const newAnnotationState = {
          allActiveAnnotations: allAnnotations,
          updatedAnnotations: newStateAnnotations,
        } as DocumentHistoryAnnotationState;

        const state = {
          annotationState: newAnnotationState,
          paragraphs: {},
        } as DocumentHistoryState;

        saveState(state);
      };

      const saveState = (state: DocumentHistoryState) => {
        if (currentStateIndex.value < states.value.length - 1) {
          clearStatesAfterIndex(currentStateIndex.value);
        }

        states.value = [...states.value, JSON.stringify(state)];

        currentStateIndex.value++;
      };

      const setInitialActiveAnnotations = (
        annotations: RevisionAnnotations
      ) => {
        if (isAnnotationsInitialized.value == true) return;

        const currentStateData = currentState.value;
        currentStateData.annotationState.allActiveAnnotations = annotations;
        states.value[currentStateIndex.value] =
          JSON.stringify(currentStateData);
      };

      const clearStatesAfterIndex = (index: number) => {
        states.value = states.value.slice(0, index + 1);
      };

      // This needs to convert a string into allAnnotations.value and paragraphs.value
      // From the current state
      const unSerializeState = (
        serializedState: string
      ): DocumentHistoryState => {
        const state = JSON.parse(serializedState);
        return state as DocumentHistoryState;
      };

      // Undo and redo logic
      // First we point the ss
      const undo = async () => {
        if (canUndo.value != true || isRestoringState.value == true) return;
        currentStateIndex.value = currentStateIndex.value - 1;
        await restoreCurrentState();
      };
      const redo = async () => {
        if (canRedo.value != true || isRestoringState.value == true) return;
        currentStateIndex.value = currentStateIndex.value + 1;
        await restoreCurrentState();
      };

      // Here we start by unserialiing the current state
      const restoreCurrentState = async () => {
        isRestoringState.value = true;

        const state = currentState.value;
        const db = useFirestore();
        const batch = writeBatch(db);

        if (state.paragraphs) {
          useDocumentParagraphsStore(documentId).saveParagraphs(
            Object.values(state.paragraphs),
            batch
          );
        }

        if (
          state.annotationState &&
          state.annotationState &&
          state.annotationState.allActiveAnnotations
        ) {
          useDocumentAnnotationsStore(documentId).restoreAnnotationState(
            state.annotationState,
            batch
          );
        }

        // documentParagraphStore.saveParagraphs(state.paragraphs, batch);
        // documentAnnotationStore.saveAnnotations(state.annotations, batch);

        await batch.commit();
        isRestoringState.value = false;
      };

      const ignoreNextStateChange = () => {
        shouldIgnoreNextStateChange.value = true;
      };

      return {
        canUndo,
        canRedo,
        isRestoringState,
        /// I want to remove these after debugging
        currentState,
        currentStateIndex,
        states,
        undo,
        redo,
        saveParagraphsState,
        saveAnnotationsState,
        ignoreNextStateChange,
        setInitialActiveAnnotations,
      };
    }
  );
