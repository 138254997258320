export const useParagraphToken = (
  documentId: string,
  tokenGlobalPosition: number
) =>
  definePiniaStore(
    `/document/${documentId}/paragraph/tokens/${tokenGlobalPosition}`,
    () => {
      const token = ref<ParagraphToken>();

      const setToken = (newToken: ParagraphToken) => {
        token.value = newToken;
      };

      const paragraphId = ref<string | undefined>();

      const setParagraphId = (newParagraphId: string) => {
        paragraphId.value = newParagraphId;
      };

      return {
        token,
        paragraphId,
        setToken,
        setParagraphId,
      };
    }
  );
