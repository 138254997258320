import { skipHydrate } from "pinia";

export const usePseudoLogin = definePiniaStore("/pseudo-login", () => {
  const pseudoId = useLocalStorage<string | undefined>("pseudo-id", undefined);

  const setPseudoId = (id: string | undefined) => {
    pseudoId.value = id;

    // Set the pseudo id to a cookie
    const authCookie = useCookie("pseudo-id", {
      secure: true,
    });

    if (id == undefined) {
      authCookie.value = undefined;
    } else {
      authCookie.value = id;
    }
  };

  const isPseduLogin = computed(
    () => pseudoId.value != undefined && pseudoId.value != "undefined"
  );

  return {
    isPseduLogin,
    pseudoId: skipHydrate(pseudoId),
    setPseudoId,
  };
});
