import { DBFactory } from "classes/database/db_factory";
import { ZoomOauthToken } from "./zoom-oauth-token.model";
import { map, Observable } from "rxjs";

export class ZoomOauthService {
  static async get(userId: string) {
    const db = DBFactory.createDatabase();
    const data = await db.get({
      path: `/zoomOAuthTokens/${userId}`,
      collection: `/zoomOAuthTokens`,
    });

    return ZoomOauthToken.fromMap(data);
  }

  static streamRecord(userId: string): Observable<ZoomOauthToken | undefined> {
    const db = DBFactory.createDatabase();
    return db
      .streamRecord({
        path: `/zoomOAuthTokens/${userId}`,
        collection: `/zoomOAuthTokens`,
      })
      .pipe(
        map((data) => {
          if (!data) return undefined;

          return ZoomOauthToken.fromMap(data);
        })
      );
  }
}
