import { doc, setDoc } from "firebase/firestore";
import {
  Checklist,
  ChecklistCriteria,
  CriteriaInstance,
} from "types/Checklist";
import { Revision } from "types/Revision";
import { SubmittedDocument } from "types/SubmittedDocument";

export const useSaveCriteriaStatisticTransaction = async (
  documentId: string | undefined,
  revision: Revision | undefined,
  submittedDocument: SubmittedDocument | undefined,
  checklist: Checklist | undefined,
  checklistCriteria: ChecklistCriteria | undefined,

  checklistSectionId: string,
  earnedPoints: number,
  criteriaInstance: CriteriaInstance
) => {
  if (
    !checklist ||
    !checklistCriteria ||
    !documentId ||
    !revision ||
    !submittedDocument
  )
    return;

  const criteriaStatisticTransction = {
    documentId: documentId,
    revisionId: revision.id,
    teacherId: useCurrentUID(),
    classroomId: submittedDocument.classroomId,
    classroomName: submittedDocument.classroomName,
    studentId: submittedDocument.studentId,
    studentName: submittedDocument.studentName,
    assignmentId: submittedDocument.assignmentId,
    assignmentName: submittedDocument.assignmentName,
    checklistId: checklist.id,
    checklistName: checklist.title,
    sectionId: checklistSectionId,
    checklistCriteriaId: checklistCriteria.id,
    checklistCriteriaLabel: checklistCriteria.label,
    earnedPoints: earnedPoints,
    maxPoints: criteriaInstance.maxPoints,
    lastUpdatedTimestamp: new Date().getTime(),
  } as CriteriaScoreStatisticTransaction;

  const db = useFirestore();
  const transactionRef = doc(
    db,
    `/assignmentStatistics/default/criteria/${documentId}_${revision.id}_${checklist.id}_${checklistSectionId}_${checklistCriteria.id}`
  );
  await setDoc(transactionRef, criteriaStatisticTransction);
};
