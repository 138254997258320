import { FirestoreClientDatabase } from "./firestore_client_database";
import { FirestoreServerDatabase } from "./firestore_server_database";

export class FirestoreDatabase {
  // Static factory method
  static createDatabase() {
    if (process.client) {
      return new FirestoreClientDatabase();
    } else {
      return new FirestoreServerDatabase();
    }
  }
}
