export const useCategorizeQuickComments = (
  quickComments: QuickComments
): CategorizedQuickComments[] => {
  // Group by category using reduce
  const groupedQuickComments = useGroupQuickComments(quickComments);

  // Convert to the desired output format
  return Object.keys(groupedQuickComments)
    .sort()
    .map((category) => ({
      category,
      quickComments: groupedQuickComments[category],
    }));
};
