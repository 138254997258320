import { useDocumentSavingStatus } from "~/stores/useDocumentSavingStatus";

export const useDocumentSavingStatusStore = (documentId: string) => {
  const { revision } = useSubmittedDocumentStore(documentId);

  if (!revision) {
    throw new Error("Revision is not defined");
  }

  return useDocumentSavingStatus(documentId, revision.id)();
};
