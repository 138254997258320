import { DocumentViewMode } from "types/enums/DocumentViewMode.enum";

export const useDocumentViewMode = (documentId: string) =>
  defineStore(`/documents/${documentId}/view/mode`, () => {
    const viewMode = ref<DocumentViewMode>(DocumentViewMode.student);

    const setDocumentViewMode = (mode: DocumentViewMode) => {
      viewMode.value = mode;
    };

    const isStudentViewMode = computed(
      () => viewMode.value === DocumentViewMode.student
    );

    const isTeacherViewMode = computed(
      () => viewMode.value === DocumentViewMode.teacher
    );

    const isCompareMode = computed(
      () => viewMode.value === DocumentViewMode.compare
    );

    const showDocumentAppBar = computed(() => {
      return isCompareMode.value != true;
    });

    const showChecklistSummary = computed(() => {
      return [DocumentViewMode.student, DocumentViewMode.compare].includes(
        viewMode.value
      );
    });

    const isConsolidatedMode = computed(() => {
      return [DocumentViewMode.compare].includes(viewMode.value);
    });

    const canSelect = computed(() => {
      return [DocumentViewMode.compare].includes(viewMode.value) != true;
    });

    const canEdit = computed(() => {
      return [DocumentViewMode.teacher].includes(viewMode.value);
    });

    return {
      setDocumentViewMode,
      isStudentViewMode,
      isTeacherViewMode,
      isCompareMode,
      isConsolidatedMode,

      showDocumentAppBar,
      showChecklistSummary,
      canSelect,
      canEdit,
    };
  });
