import {
  collection,
  orderBy,
  query,
  getDocs,
  doc,
  onSnapshot,
  writeBatch,
  WriteBatch,
  QuerySnapshot,
  DocumentData,
  updateDoc,
  Unsubscribe,
} from "@firebase/firestore";

import { Observable, combineLatest } from "rxjs";
import {
  WordChecklistCells,
  WordChecklistRow,
  WordChecklistRows,
} from "types/WordChecklist";
import { Paragraph, Paragraphs } from "~/types/Paragraph";

export const useDocumentRevisionParagraphs = (
  documentId: string,
  revisionId: string
) =>
  definePiniaStore(
    `/documents/${documentId}/revisions/${revisionId}/paragraphs`,
    () => {
      const wordChecklistRows = ref<WordChecklistRows>([]);
      const wordChecklistCells = ref<WordChecklistCells>([]);
      const paragraphs = ref<Paragraphs>([]);
      const mapTokenIdsToPositions = ref<{ [key: string]: number }>({});
      const subscription = ref<Unsubscribe | undefined>();

      // I'm probably going to nee to assign indexes to all pargaphs...maybe. Probably do this later.
      // Definitelt want to try adding ids to each token.
      const parseParagraphs = (
        snapshot: QuerySnapshot<DocumentData>,
        allParagraphs: Paragraphs,
        allTokens: ParagraphTokens
      ) => {
        return snapshot.docs.map((doc) => {
          var paragraph = {
            ...doc.data(),
            id: doc.id,
          } as Paragraph;

          // tokens.value = tokens.value.concat(paragraph.tokens);
          allParagraphs.push(paragraph);
          allTokens.concat(paragraph.tokens);

          return paragraph;
        });
      };

      const isParagraphsInitialized = ref(false);

      const initialize = (documentId: string, revisionId: string) => {
        const db = useFirestore();

        // Here I want to pull all rows from the checklistRows collection and then filter out only the rows relevant to the general section
        const checklistRowsRef = collection(
          db,
          `/documents/${documentId}/revisions/${revisionId}/checklistRows`
        );
        const checklistRowsQuery = query(
          checklistRowsRef,
          orderBy("index", "asc")
        );

        if (subscription.value) {
          subscription.value();
        }

        subscription.value = onSnapshot(checklistRowsQuery, (snapshot) => {
          var rows = snapshot.docs.map((doc) => {
            return doc.data() as WordChecklistRow;
          });

          var isAccumulatingRows = false;
          var accumulatedRows = [];
          var allCells = [];
          for (var row of rows) {
            for (var cell of row.cells) {
              for (var paragraph of cell.paragraphs) {
                if (paragraph.text.toLowerCase().includes("parent check")) {
                  allCells.push(cell);
                  break;
                }
              }
            }
          }

          wordChecklistCells.value = allCells;
        });
      };

      const { save, saved } = useDocumentSavingStatusStore(documentId);

      const saveParagraphs = async (
        paragraphs: Paragraphs,
        initialBatch?: WriteBatch
      ) => {
        var startTime = new Date().getTime();

        const db = useFirestore();

        var batch = initialBatch ?? writeBatch(db);

        if (initialBatch == undefined) {
          save();
        }

        for (var paragraph of paragraphs) {
          var collectionRef = paragraph.collectionRef;
          var paragraphRef = doc(
            db,
            `/documents/${documentId}/revisions/${revisionId}/${collectionRef}/${paragraph.id}`
          );

          // updateDoc(paragraphRef, paragraph).then();
          batch.set(paragraphRef, paragraph, { merge: true });
          // var endUpdateTime = new Date().getTime();
          // console.log(
          //   "Time to update paragraph: ",
          //   (endUpdateTime - startTime) / 1000
          // );
        }

        if (initialBatch == undefined) {
          await batch.commit();
          // var endBatchTime = new Date().getTime();
          // console.log("Time to commit: ", (endBatchTime - startTime) / 1000);

          saved();
        }

        var endTime = new Date().getTime();
      };

      const tokenFromGlobalPosition = computed(() => {
        return (globalPosition: number) => {
          const tokenStore = useParagraphTokenStore(documentId, globalPosition);

          const { token } = storeToRefs(tokenStore);
          return token.value;
        };
      });

      const tokensFromGlobalPositions = computed(() => {
        return (globalPositions: number[]) => {
          return globalPositions.map((globalPosition) => {
            return tokenFromGlobalPosition.value(globalPosition);
          });
        };
      });

      const convertTokensToText = computed(() => {
        return (tokens: ParagraphToken[]) => {
          return tokens
            .map((token) => token.text)
            .join("")
            .trim();
        };
      });

      const convertTokenGlobalPositionsToText = computed(() => {
        return (globalPositions: number[]) => {
          const tokens = tokensFromGlobalPositions.value(globalPositions);

          return convertTokensToText.value(tokens);
        };
      });

      const getTokenParagraph = (
        token: ParagraphToken
      ): Paragraph | undefined => {
        var paragraph: Paragraph | undefined = undefined;

        for (var p of paragraphs.value) {
          var index = p.tokens.findIndex(
            (t) => t.globalPosition == token.globalPosition
          );

          if (index >= 0) {
            paragraph = p;
            break;
          }
        }

        return paragraph;
      };

      const getTokenGlobalPositionParagraph = (
        tokenGlobalPosition: number
      ): Paragraph | undefined => {
        var token = tokensFromGlobalPositions.value([tokenGlobalPosition])[0];

        if (!token) return undefined;

        return getTokenParagraph(token);
      };

      const getParagraphFromId = (
        paragraphId: string
      ): Paragraph | undefined => {
        var paragraph = paragraphs.value.filter((p) => p.id == paragraphId)[0];

        return paragraph;
      };

      const bodyParagraphs = computed(() => {
        return paragraphs.value.filter((p) => {
          return (
            p.collectionRef == "paragraphs" &&
            p.section == "body" &&
            p.tokens.length > 0 &&
            p.text.replace(/^ +| +$/g, "") != "\n"
          );
        });
      });

      const numBodyParagraphs = computed(() => {
        return bodyParagraphs.value.length;
      });

      const isTokenBolded = computed(() => {
        return (tokenGlobalPosition: number) => {
          var token = tokenFromGlobalPosition.value(tokenGlobalPosition);

          return token.formatting.bold;
        };
      });

      const isTokenUnderlined = computed(() => {
        return (tokenGlobalPosition: number) => {
          var token = tokenFromGlobalPosition.value(tokenGlobalPosition);

          if (token == undefined) return false;

          return token.formatting.underline;
        };
      });

      const isTokenItalic = computed(() => {
        return (tokenGlobalPosition: number) => {
          var token = tokenFromGlobalPosition.value(tokenGlobalPosition);

          if (token == undefined) return false;

          return token.formatting.italic;
        };
      });

      const isTokenStrike = computed(() => {
        return (tokenGlobalPosition: number) => {
          var token = tokenFromGlobalPosition.value(tokenGlobalPosition);

          if (token == undefined) return false;

          return token.formatting.strikeThrough;
        };
      });

      const setParagraphs = (newParagraphs: Paragraphs) => {
        paragraphs.value = newParagraphs;

        // tokens = [];
        // for (var paragraph of paragraphs.value) {
        //   tokens = tokens.concat(paragraph.tokens);
        // }
      };

      const selectedParagraphs = () => {
        const documentSelectionStore = useDocumentSelectionStore(documentId);
        const { selectedTokenPositions } = storeToRefs(documentSelectionStore);

        var selectedParagraphIds: string[] = [];

        for (var tokenPosition of selectedTokenPositions.value) {
          for (var paragraph of paragraphs.value) {
            if (
              paragraph.firstTokenGlobalPosition == undefined ||
              paragraph.lastTokenGlobalPosition == undefined
            ) {
              continue;
            }
            if (
              paragraph.firstTokenGlobalPosition != null &&
              paragraph.lastTokenGlobalPosition != null &&
              paragraph.firstTokenGlobalPosition <= tokenPosition &&
              paragraph.lastTokenGlobalPosition >= tokenPosition
            ) {
              selectedParagraphIds.push(paragraph.id);
              break;
            }
          }
        }

        var uniqueIds = [...new Set(selectedParagraphIds)];
        var filteredParagraphs = paragraphs.value.filter(
          (paragraph: Paragraph) => uniqueIds.includes(paragraph.id)
        );

        return filteredParagraphs;
      };

      const tokenGlobalPositionFromId = computed(() => {
        return (id: string) => {
          return mapTokenIdsToPositions.value[id];
        };
      });

      return {
        paragraphs,
        bodyParagraphs,
        numBodyParagraphs,
        wordChecklistRows,
        wordChecklistCells,
        tokenFromGlobalPosition,
        tokensFromGlobalPositions,
        convertTokensToText,
        convertTokenGlobalPositionsToText,
        tokenGlobalPositionFromId,
        isTokenBolded,
        isTokenUnderlined,
        isTokenItalic,
        isTokenStrike,
        selectedParagraphs,
        initialize,
        saveParagraphs,
        getTokenParagraph,
        getTokenGlobalPositionParagraph,
        getParagraphFromId,
        setParagraphs,
      };
    }
  );
