import { doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { Paragraph } from "types/Paragraph";
import { useIsSentenceOpenerMarker } from "~/composables/useIsSentenceOpenerMarker";

export const useDocumentParagraph = (
  documentId: string,
  revisionId: string,
  paragraphId: string
) =>
  definePiniaStore(
    `document/${documentId}/${revisionId}/paragraphs/${paragraphId}`,
    () => {
      const paragraph = ref<Paragraph | undefined>(undefined);
      const lastSavedParagraph = ref<string | undefined>(undefined);
      const paragraphsSubscription = ref<Unsubscribe | undefined>();

      const documentTokenIdPositionMapStore =
        useDocumentTokenIdPositionMapStore(documentId);

      const streamParagraph = (baseParagraph: Paragraph) => {
        lastSavedParagraph.value = JSON.stringify(baseParagraph);
        paragraph.value = baseParagraph;

        const db = useFirestore();

        const paragraphRef = doc(
          db,
          `/documents/${documentId}/revisions/${revisionId}/${baseParagraph.collectionRef}/${paragraphId}`
        );

        if (paragraphsSubscription.value) {
          paragraphsSubscription.value();
        }

        paragraphsSubscription.value = onSnapshot(paragraphRef, (snapshot) => {
          const data = snapshot.data();

          if (!data) return;

          lastSavedParagraph.value = JSON.stringify({
            ...data,
            id: snapshot.id,
          });

          paragraph.value = {
            ...data,
            id: snapshot.id,
          } as Paragraph;

          // Here is where I think I also ought to push tokens?
          for (var token of paragraph.value.tokens) {
            var tokenStore = useParagraphTokenStore(
              documentId,
              token.globalPosition
            );
            tokenStore.setToken(token);
            tokenStore.setParagraphId(paragraph.value.id);

            documentTokenIdPositionMapStore.mapToken(token);
          }
        });
      };

      const getClosestMarkerToGlobalPosition = (
        tokenPositon: number
      ): ParagraphToken | undefined => {
        var markers = paragraph.value?.markers?.filter((marker) => {
          // Check if the marker matches the pattern
          return useIsSentenceOpenerMarker(marker.text);
        });

        if (markers == undefined || markers.length == 0) return undefined;

        const closestMarker = markers.reduce(
          (closest: ParagraphToken | null, currentMarker: ParagraphToken) => {
            if (
              currentMarker.position <= tokenPositon &&
              (closest === null ||
                tokenPositon - currentMarker.position <
                  tokenPositon - closest.position)
            ) {
              return currentMarker;
            }

            return closest;
          },
          null as ParagraphToken | null
        );

        return closestMarker || undefined; // TypeScript prefers undefined over null for not found
      };

      return {
        streamParagraph,
        paragraph,
        lastSavedParagraph,
        getClosestMarkerToGlobalPosition,
      };
    }
  );
