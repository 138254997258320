<script lang="ts" setup>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

const props = defineProps({
  color: {
    type: String,
    default: "primary",
  },
  showSpinner: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(["click"]);

const dynamicStyles: any = {};

switch (props.color) {
  case "secondary":
    dynamicStyles[
      `bg-secondary-default hover:bg-secondary-shade text-secondary-on`
    ] = true;
    break;
  case "medium": // Medium is the default color
    dynamicStyles[`bg-medium-default hover:bg-medium-tint text-medium-on`] =
      true;
    break;
  case "google":
    dynamicStyles[`bg-google-default hover:bg-google-shade text-google-on`] =
      true;
    break;
  case "danger":
    dynamicStyles[`bg-danger-default hover:bg-danger-shade text-danger-on`] =
      true;
    break;
  case "success":
    dynamicStyles[`bg-success-default hover:bg-success-shade text-success-on`] =
      true;
    break;
  case "primary":
  default:
    dynamicStyles[`bg-primary-default hover:bg-primary-shade text-primary-on`] =
      true;
    break;
}

const onClick = () => {
  if (props.disabled) return;

  emits("click");
};
</script>

<template>
  <div class="relative rounded cursor-pointer h-[42px]">
    <button
      @click="onClick"
      class="px-4 w-full rounded select-none h-full flex flex-col items-center justify-center"
      :class="{
        ...dynamicStyles,
        '!text-medium-tint !bg-light-default cursor-not-allowed ': disabled,
        ' !active:bg-opacity-20': disabled != true,
      }"
    >
      <slot />
    </button>
    <transition>
      <div
        v-if="showSpinner"
        class="flex rounded items-center justify-center absolute top-0 left-0 w-full h-full bg-overlay"
      >
        <PulseLoader :loading="true" color="white" size="8px"></PulseLoader>
      </div>
    </transition>
  </div>
</template>

<style scoped>
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 150ms ease-in;
}

.v-enter-to,
.v-leave-from {
  opacity: 1;
}
</style>
