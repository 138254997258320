export enum DocumentSubmissionType {
  keyWordOutline = "keyWordOutline",
  roughDraft = "roughDraft",
  finalDraft = "finalDraft",
}

export const DocumentSubmissionTypeLabels = {
  [DocumentSubmissionType.keyWordOutline]: {
    shortLabel: "KWO",
    longLabel: "Key Word Outline",
  },
  [DocumentSubmissionType.roughDraft]: {
    shortLabel: "Rough Draft",
    longLabel: "Rough Draft",
  },
  [DocumentSubmissionType.finalDraft]: {
    shortLabel: "Final Draft",
    longLabel: "Final Draft",
  },
};

export const DocumentSubmissionTypes = [
  {
    value: DocumentSubmissionType.keyWordOutline,
    label:
      DocumentSubmissionTypeLabels[DocumentSubmissionType.keyWordOutline]
        .longLabel,
  },
  {
    value: DocumentSubmissionType.roughDraft,
    label:
      DocumentSubmissionTypeLabels[DocumentSubmissionType.roughDraft].longLabel,
  },
  {
    value: DocumentSubmissionType.finalDraft,
    label:
      DocumentSubmissionTypeLabels[DocumentSubmissionType.finalDraft].longLabel,
  },
];
