import { EvaluationRelevantInstance } from "types/EvaluationResults";

export const useSortRelevantInstances = (
  relevantInstances: EvaluationRelevantInstance[]
) => {
  relevantInstances.sort((a, b) => {
    // Not goign to sort by vlaidity yet.
    // if (a.confidenceScore == b.confidenceScore) {
    //   // sort which ever one is valid first
    //   if (a.valid == b.valid) {
    //     return 0;
    //   }

    //   return a.valid ? -1 : 1;
    // }

    return (b.confidenceScore ?? 1) - (a.confidenceScore ?? 1);
  });

  return relevantInstances;
};
