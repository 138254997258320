import { doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { EvaluationResults } from "types/EvaluationResults";
import { EvaluationResultsStatus } from "~/types/enums/EvaluationResultsStatus";

export const useDocumentParagraphMetric = (
  documentId: string,
  revisionId: string
) => {
  return definePiniaStore(
    `/document/${documentId}/revision/${revisionId}/metric`,
    () => {
      const paragraphMetricResults = ref<EvaluationResults | undefined>();

      const paragraphMetricId = ref<string | undefined>();
      const paragraphMetricResultsSubscription = ref<Unsubscribe | undefined>();

      const isInitialized = ref(false);
      const initializeParagraphMetric = async (metricId: string) => {
        paragraphMetricId.value = metricId;
        if (isInitialized.value) return;

        const db = useFirestore();
        const paragraphMetricResultsRef = doc(
          db,
          `/documents/${documentId}/revisions/${revisionId}/evaluationResults/${metricId}`
        );

        if (paragraphMetricResultsSubscription.value) {
          paragraphMetricResultsSubscription.value();
        }

        paragraphMetricResultsSubscription.value = onSnapshot(
          paragraphMetricResultsRef,
          (doc) => {
            paragraphMetricResults.value = {
              ...doc.data(),
              id: doc.id,
            } as EvaluationResults;
          }
        );

        // Here we need to actually make an api call.
        await useFetch(
          `/api/${documentId}/${revisionId}/metric/${metricId}/evaluate`,
          {
            method: "POST",
            headers: await useApiHeaders(),
          }
        );

        isInitialized.value = true;
      };

      const numDocumentBodyParagraphs = computed(() => {
        const documentParagraphStore =
          useDocumentRevisionParagraphsStore(documentId);
        const { paragraphs } = storeToRefs(documentParagraphStore);

        if (
          !paragraphMetricResults.value ||
          paragraphMetricResults.value.evaluationResult.length == 0
        ) {
          return paragraphs.value.filter((p) => p.section == "body").length;
        }

        return paragraphMetricResults.value.evaluationResult[0]
          .relevantInstances.length;
      });

      const isLoading = computed(() => {
        if (!paragraphMetricId.value) return false;

        return (
          paragraphMetricResults.value == undefined ||
          paragraphMetricResults.value.status ==
            EvaluationResultsStatus.evaluating
        );
      });

      const getParagraphRelevantInstanceFromId = (paragraphId: string) => {
        if (!paragraphMetricResults.value) return null;

        const relevantInstances =
          paragraphMetricResults.value.evaluationResult[0].relevantInstances;

        const matchingRelevantInstances = relevantInstances.filter((ri) => {
          return ri.uid == paragraphId;
        });

        if (matchingRelevantInstances.length == 0) return null;

        return matchingRelevantInstances[0];
      };

      return {
        numDocumentBodyParagraphs,
        isLoading,
        initializeParagraphMetric,
        getParagraphRelevantInstanceFromId,
      };
    }
  );
};
