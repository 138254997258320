<script lang="ts" setup></script>

<template>
  <div class="flex flex-col mb-4">
    <label class="text-h5 mb-2">
      <slot name="label" />
    </label>
    <slot />
  </div>
</template>

<style scoped></style>
