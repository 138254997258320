export class BaseModel {
  pathPrefixOverride?: string;

  modelDatabaseConfig: ModelDatabaseConfig = {
    collection: "",
    path: "",
  };

  constructor(data = {}) {
    Object.assign(this, data);
  }

  get databaseConfig(): ModelDatabaseConfig {
    return this.modelDatabaseConfig;
  }

  // Convert instance to a map (plain object)
  toMap() {
    // Create a plain object from the instance properties
    const map = {} as { [key: string]: any };
    for (const key of Object.keys(this)) {
      const value = (this as any)[key];

      // Handle nested BaseModel instances recursively
      if (value instanceof BaseModel) {
        map[key] = value.toMap();
      } else if (value instanceof Array) {
        map[key] = value.map((item) => {
          if (item instanceof BaseModel) {
            return item.toMap();
          } else {
            return item;
          }
        });
      } else {
        map[key] = value;
      }

      if (typeof value === "function") {
        delete map[key];
      }
    }

    delete map.modelDatabaseConfig;
    return map as ModelDatabaseData;
  }

  // Static method to create an instance from a map
  static fromMap(map: ModelDatabaseData) {
    const instance = new this() as any;
    for (const key in map) {
      const value = map[key];

      // Assuming that nested objects are not to be automatically converted to BaseModel instances
      // You can add custom logic here if you have nested BaseModel instances
      instance[key] = value;
    }
    return instance;
  }
}
