import type { RouterOptions } from "@nuxt/schema";

// https://router.vuejs.org/api/interfaces/routeroptions.html
export default <RouterOptions>{
  routes: (_routes) => {
    const { ssrContext } = useNuxtApp();

    const subdomain = useSubdomain();

    if (ssrContext?.event.context.subdomain.includes("student")) {
      subdomain.value = "students";
    }

    if (subdomain.value?.includes("student")) {
      var studentRoutes = _routes.filter((i) => i.path.includes("/students"));

      const studentRouteMapped = studentRoutes.map((i) => {
        return {
          ...i,
          path:
            i.path === "/students"
              ? i.path.replace("/students", "")
              : i.path.replace("/students/", "/"),
        };
      });

      return studentRouteMapped;
    }
  },
};
