import { EvaluationResults } from "types/EvaluationResults";

export const useIsEvaluationResultsValid = (
  evaluationResults: EvaluationResults | undefined
) => {
  if (evaluationResults == undefined) return false;

  if (evaluationResults.evaluationResult == undefined) return false;

  if (evaluationResults.evaluationResult.length == 0) return false;

  return true;
};
