export const useIsSentenceOpenerMarker = (text: string) => {
  // Update the pattern to allow any opening bracket followed by any digit followed by any closing bracket
  const pattern = /^[\}\]\)\{\[\(]\d[\}\]\)\{\[\(]$/;

  // A computed property that returns true if the text matches the pattern
  const matchesPattern = computed(() => pattern.test(text));

  return {
    matchesPattern,
  };
};
