import {
  WriteBatch,
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  writeBatch,
  Unsubscribe,
} from "firebase/firestore";
import {
  RevisionAnnotation,
  RevisionAnnotations,
} from "~/types/RevisionAnnotation";
import {
  DocumentHistoryAnnotationState,
  DocumentHistoryState,
} from "types/DocumentHistoryState";

export const useAnnotations = (documentId: string, revisionId: string) =>
  definePiniaStore(`/${documentId}/${revisionId}/annotations`, () => {
    const allAnnotations = ref<RevisionAnnotations>([]);
    const lastEmittedAnnotations = ref<{ [key: string]: RevisionAnnotation }>(
      {}
    );

    const isLoadingState = ref(false);

    const isInitialized = ref(false);

    const annotationsSubscription = ref<Unsubscribe | undefined>();

    const initialize = () => {
      if (isInitialized.value) {
        return;
      }

      const db = useFirestore();
      const annotationsRef = collection(
        db,
        `/documents/${documentId}/revisions/${revisionId}/annotations`
      );

      const annotationsQuery = query(annotationsRef, orderBy("startId"));

      if (annotationsSubscription.value) {
        annotationsSubscription.value();
      }

      annotationsSubscription.value = onSnapshot(
        annotationsQuery,
        (snapshot) => {
          lastEmittedAnnotations.value = {};

          var annotations = snapshot.docs.map((doc) => {
            var annotation = {
              id: doc.id,
              ...doc.data(),
            } as RevisionAnnotation;

            annotation.tokenGlobalPositions =
              annotation.tokenGlobalPositions.sort((a, b) => a - b);

            lastEmittedAnnotations.value[annotation.id!] = JSON.parse(
              JSON.stringify(annotation)
            );

            return annotation;
          });

          allAnnotations.value = annotations;
          useDocumentHistoryStore(documentId).setInitialActiveAnnotations(
            annotations
          );
        }
      );

      isInitialized.value = true;
    };

    const restoreAnnotationState = async (
      annotationState: DocumentHistoryAnnotationState,
      initialBatch: WriteBatch | undefined
    ) => {
      isLoadingState.value = true;
      const db = useFirestore();

      const batch = initialBatch ?? writeBatch(db);

      const annotations = Object.values(annotationState.updatedAnnotations);

      for (var annotation of annotations) {
        const annotationRef = doc(
          db,
          `/documents/${documentId}/revisions/${revisionId}/annotations/${annotation.id}`
        );

        batch.set(annotationRef, annotation);
      }

      for (var annotation of allAnnotations.value) {
        var index = annotationState.allActiveAnnotations.findIndex(
          (a) => a.id == annotation.id
        );

        if (index < 0) {
          const annotationRef = doc(
            db,
            `/documents/${documentId}/revisions/${revisionId}/annotations/${annotation.id}`
          );
          batch.delete(annotationRef);
        }
      }

      // If htere was an annotation in the incoming state that wasn't in the current state then we need to restore it.
      for (var annotation of annotationState.allActiveAnnotations) {
        var index = allAnnotations.value.findIndex(
          (a) => a.id == annotation.id
        );

        if (index < 0) {
          const annotationRef = doc(
            db,
            `/documents/${documentId}/revisions/${revisionId}/annotations/${annotation.id}`
          );
          batch.set(annotationRef, annotation);
        }
      }

      if (initialBatch == undefined) {
        await batch.commit();
      }
    };

    const lastAnnotationState = (
      annotationId: string
    ): RevisionAnnotation | undefined => {
      return lastEmittedAnnotations.value[annotationId];
    };

    const annotationWithDeductions = computed(() => {
      return allAnnotations.value.filter(
        (a) => a.shouldDeductMechanics == true
      );
    });

    return {
      allAnnotations,
      annotationWithDeductions,
      lastAnnotationState,
      restoreAnnotationState,
      initialize,
    };
  });
