import { BehaviorSubject } from "rxjs";

// I could eventually enhance this to use an id so I can have differnet event busses per tour id
export const useTourEventBus = defineStore("/tourEventBus", () => {
  const events = new BehaviorSubject<TourEvent | undefined>(undefined);

  const emit = (event: TourEvent) => {
    events.next(event);
  };

  return { events, emit };
});
