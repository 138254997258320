import { CriteriaInstance, ParagraphPositionData } from "types/Checklist";
import { EvaluationResults } from "types/EvaluationResults";

export const useInferInstanceBodyParagraphPositions = (
  documentId: string,
  criteriaInstance: CriteriaInstance,
  criteriaParagraphPositionsData: ParagraphPositionData[] | undefined,
  evaluationResults: EvaluationResults | undefined,
  hasCorrectNumberOfBodyParagraphs: boolean,
  mapParagraphPositionToBodyParagraphPosition: { [key: number]: number }
): { areKeysMatched: boolean; hasUnassignedInstances: boolean } | undefined => {
  // First of all, if this evaluation method is not paragraph based, ignore this step

  if (criteriaInstance.evaluationMethod != "paragraph") return;

  if (criteriaParagraphPositionsData == undefined) return;

  if (useIsEvaluationResultsValid(evaluationResults) != true) return;

  const relevantInstances =
    evaluationResults!.evaluationResult[0].relevantInstances;

  for (var instance of relevantInstances) {
    // If the instance already has something assigned then we can skip
    // And we can make sure that the paragraph position of this body paragraph is set properly.
    if (
      instance.bodyParagraphPosition != undefined &&
      instance.paragraphPosition != null
    ) {
      useDocumentEvaluativeParagraphsStore(
        documentId
      ).mapBodyParagraphToParagraphPosition(
        instance.bodyParagraphPosition,
        instance.paragraphPosition
      );
      continue;
    }

    const evaluativeParagraph = useDocumentEvaluativeParagraphsStore(
      documentId
    ).getEvaluativeParagraphByTokenIndex(instance.tokens[0].position);

    if (!evaluativeParagraph) continue;

    instance.paragraphPosition = evaluativeParagraph.position;

    // If wer don't have the corret number of body paragraphs then we check if
    // we've already matched this evaluativeParagraphs position to a body paragraph
    if (hasCorrectNumberOfBodyParagraphs != true) {
      instance.bodyParagraphPosition =
        mapParagraphPositionToBodyParagraphPosition[instance.paragraphPosition];

      continue;
    }

    instance.paragraphPosition = evaluativeParagraph.position;
    instance.bodyParagraphPosition =
      useDocumentEvaluativeParagraphsStore(documentId).bodyIndexFromAllIndex(
        evaluativeParagraph.index
      ) + 1;
  }

  const matchedParagraphPositions = relevantInstances.map(
    (instance) => instance.bodyParagraphPosition
  );

  const uniqueMatchedParagraphPositions = [
    ...new Set(matchedParagraphPositions),
  ];

  // This checks to make sure thatevery paragraph that expects a paragraph position has one
  // But it does not account for cases where a user did not supply the correnct number of paragraphs
  // So what we also should check is whether there are unaccounted for instances or not
  const areKeysMatched = criteriaParagraphPositionsData.every(
    (paragraphPositionData) =>
      uniqueMatchedParagraphPositions.includes(paragraphPositionData.position)
  );

  const unassignedInstances = relevantInstances.filter(
    (relevantInstance) => relevantInstance.bodyParagraphPosition == undefined
  );

  return {
    areKeysMatched,
    hasUnassignedInstances: unassignedInstances.length > 0,
  };
};
