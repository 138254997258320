import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "../base.model";

export type GuidedTours = GuidedTour[];

type GuidedTourConstructorParams = {
  id?: string;
  name: string;
  restrictedPath?: string;
  tourPromptType: "button" | "dialog";
  buttonLabel?: string;
  buttonRightPosition?: string;
  dialogTitle?: string;
  dialogText?: string;
  steps: GuidedTourSteps;
  allowRestart: boolean;
  restartButtonLabel?: string;
};

export class GuidedTour extends BaseModel {
  id?: string;
  name: string;
  restrictedPath?: string;
  tourPromptType: "button" | "dialog";
  dialogTitle?: string;
  dialogText?: string;
  buttonLabel?: string;
  buttonRightPosition?: string;
  steps: GuidedTourSteps;
  allowRestart: boolean;
  restartButtonLabel?: string;

  constructor(params: GuidedTourConstructorParams) {
    super(params);
    this.id = params.id;
    this.name = params.name;
    this.restrictedPath = params.restrictedPath;
    this.tourPromptType = params.tourPromptType ?? "button";
    this.dialogTitle = params.dialogTitle;
    this.dialogText = params.dialogText;
    this.buttonLabel = params.buttonLabel;
    this.buttonRightPosition = params.buttonRightPosition;
    this.steps = params.steps;
    this.allowRestart = params.allowRestart;
    this.restartButtonLabel = params.restartButtonLabel;
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: "/guidedTours",
      path: `/guidedTours/${this.id}`,
    };
  }

  static fromMap(map: any) {
    return new GuidedTour(map);
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }

  async delete() {
    const db = DBFactory.createDatabase();
    await db.delete(this.databaseConfig);
  }
}
