import { Observable, map } from "rxjs";
import { GuidedTour, GuidedTours } from "./guided-tour";
import { DBFactory } from "classes/database/db_factory";

export class GuidedToursService {
  static streamAllTours(): Observable<GuidedTours> {
    const db = DBFactory.createDatabase();
    return db
      .streamList({
        path: "/guidedTours",
        collection: "/guidedTours",
      })
      .pipe(
        map((data) => {
          return data.map((map) => {
            return GuidedTour.fromMap(map);
          });
        })
      );
  }
}
