import { RevisionAnnotation } from "types/RevisionAnnotation";

export const useAnnotationFirstToken = (
  annotation: RevisionAnnotation
): number | undefined => {
  if (
    !annotation.tokenGlobalPositions ||
    annotation.tokenGlobalPositions.length == 0
  ) {
    return undefined;
  }

  var tokenPositions = annotation.tokenGlobalPositions;
  // Ensure numerical sorting
  tokenPositions.sort((a, b) => a - b);
  var firstTokenPosition = tokenPositions[0];

  // If the first token position is negative or not a number, return undefined
  if (firstTokenPosition < 0 || isNaN(firstTokenPosition)) {
    return undefined;
  }

  return firstTokenPosition;
};