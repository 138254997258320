import { map, Observable } from "rxjs";
import { DBFactory } from "~/classes/database/db_factory";
import {
  StudentLinkedAccount,
  StudentLinkedAccounts,
} from "../studentLinkedAccounts/student-linked-account.model";
import { StudentNote } from "./student-notes.model";
import { Student, Students } from "./student.model";
export class StudentsService {
  static async get(id: string) {
    const db = DBFactory.createDatabase();

    const databaseData = await db.get({
      collection: "/students",
      path: `/students/${id}`,
    });

    return Student.fromMap(databaseData);
  }

  static async listClassroomStudents(classroomId: string, userId: string) {
    const db = DBFactory.createDatabase();

    const databaseData = await db.list(
      {
        collection: `/students`,
        path: `/students`,
      },
      this.buildClassroomStudentsQuery(classroomId, userId)
    );

    return databaseData.map((data) => {
      return Student.fromMap(data);
    });
  }

  static streamClassroomStudents = (classroomId: string, userId: string) => {
    const db = DBFactory.createDatabase();

    return db
      .streamList(
        {
          collection: `/students`,
          path: `/students`,
        },
        this.buildClassroomStudentsQuery(classroomId, userId)
      )
      .pipe(
        map((databaseData) => {
          return databaseData
            .map((data) => Student.fromMap(data))
            .filter((student) => student.isArchived != true);
        })
      );
  };

  static async listStudentLinkedAccounts(studentId: string, userId: string) {
    const db = DBFactory.createDatabase();

    const databaseData = await db.list(
      {
        collection: `/students/${studentId}/linkedAccounts`,
        path: `/students/${studentId}/linkedAccounts`,
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
        {
          type: "where",
          field: "isArchived",
          operator: "==",
          value: false,
        },
      ]
    );

    return databaseData
      .map((data) => {
        return StudentLinkedAccount.fromMap(data);
      })
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }

  static listClassroomStudentLinkedAccounts = async ({
    classroomId,
    userId,
  }: {
    classroomId: string;
    userId: string;
  }) => {
    const db = DBFactory.createDatabase();
    const data = await db.list(
      {
        collection: `/linkedAccounts`,
        path: `/linkedAccounts`,
        collectionGroup: "linkedAccounts",
      },
      [
        {
          type: "where",
          field: "userId",
          operator: "==",
          value: userId,
        },
        {
          type: "where",
          field: "classroomId",
          operator: "==",
          value: classroomId,
        },
      ]
    );

    return data.map((data) => StudentLinkedAccount.fromMap(data));
  };

  static streamStudentLinkedAccounts = (
    studentId: string,
    userId: string
  ): Observable<StudentLinkedAccounts> => {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          collection: `/students/${studentId}/linkedAccounts`,
          path: `/students/${studentId}/linkedAccounts`,
        },
        [
          {
            type: "where",
            field: "userId",
            operator: "==",
            value: userId,
          },
          {
            type: "where",
            field: "isArchived",
            operator: "==",
            value: false,
          },
        ]
      )
      .pipe(
        map((data) => {
          return data
            .map((data) => StudentLinkedAccount.fromMap(data))
            .sort((a, b) => a.displayOrder - b.displayOrder);
        })
      );
  };

  static async getStudentLinkedAccount(
    studentId: string,
    studentLinkedAccountId: string
  ) {
    const db = DBFactory.createDatabase();

    const databaseData = await db.get({
      collection: `/students/${studentId}/linkedAccounts`,
      path: `/students/${studentId}/linkedAccounts/${studentLinkedAccountId}`,
    });

    return StudentLinkedAccount.fromMap(databaseData);
  }

  static buildClassroomStudentsQuery(classroomId: string, userId: string) {
    return [
      {
        type: "where",
        field: "userId",
        operator: "==",
        value: userId,
      },
      {
        type: "where",
        field: "classroomIds",
        operator: "array-contains",
        value: classroomId,
      },

      {
        type: "orderBy",
        field: "name",
        direction: "asc",
      },
    ] as ModelQueryConfig;
  }

  static saveStudents = async (students: Students) => {
    const db = DBFactory.createDatabase();
    await db.batchUpdate(students);
  };

  static deleteStudents = async (students: Students) => {
    const db = DBFactory.createDatabase();
    await db.batchDelete(students);
  };

  static streamClassroomStudentNotes = ({
    classroomId,
    userId,
  }: {
    classroomId: string;
    userId: string;
  }) => {
    const db = DBFactory.createDatabase();
    return db
      .streamList(
        {
          collection: "/studentNotes",
          path: `/studentNotes`,
        },
        [
          {
            type: "where",
            field: "classroomId",
            operator: "==",
            value: classroomId,
          },
          {
            type: "where",
            field: "teacherId",
            operator: "==",
            value: userId,
          },
        ]
      )
      .pipe(
        map((data) => {
          return data.map((data) => StudentNote.fromMap(data));
        })
      );
  };

  static fetchStudentNote = async ({ studentId }: { studentId: string }) => {
    const db = DBFactory.createDatabase();

    const databaseData = await db.get({
      collection: `/studentNotes`,
      path: `/studentNotes/${studentId}`,
    });

    return StudentNote.fromMap(databaseData);
  };
}
