// plugins/algolia.client.js

import algoliasearch from "algoliasearch/lite";
import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  const client = algoliasearch(
    useRuntimeConfig().public.algolia.applicationId,
    useRuntimeConfig().public.algolia.apiKey
  );

  nuxtApp.vueApp.provide("algolia", { client });
});
