import { CriteriaInstance, ParagraphPositionData } from "types/Checklist";

export const useEvaluationCriteriaInstance = (
  documentId: string,
  revisionId: string,
  checklistSectionId: string,
  checklistCriteriaId: string,
  criteriaInstance: CriteriaInstance
) =>
  definePiniaStore(
    `evaluation/criteria/instance/${documentId}/${revisionId}/${checklistSectionId}/${checklistCriteriaId}`,
    () => {
      const documentChecklistStore = useDocumentChecklistStore(documentId);
      const { defaultParagraphPositionsData } = storeToRefs(
        documentChecklistStore
      );

      const documentEvaluativeParagraphsStore =
        useDocumentEvaluativeParagraphsStore(documentId);
      const { numBodyParagraphs } = storeToRefs(
        documentEvaluativeParagraphsStore
      );

      /// So in this utility I want to start by establishing the kind of criteria this is.
      const criteriaParagraphPositionsData = ref<ParagraphPositionData[]>([]);

      const populateParagraphPositionData = () => {
        // If the criteria has inheritted paragraphPositionLables then we set that
        if (
          criteriaInstance.paragraphPositionsData != undefined &&
          criteriaInstance.paragraphPositionsData.length > 0
        ) {
          criteriaParagraphPositionsData.value =
            criteriaInstance.paragraphPositionsData;
          return;
        }

        if (defaultParagraphPositionsData.value != undefined) {
          criteriaInstance.paragraphPositionsData = JSON.parse(
            JSON.stringify(defaultParagraphPositionsData.value)
          ) as ParagraphPositionData[];

          criteriaParagraphPositionsData.value =
            criteriaInstance.paragraphPositionsData;
          useDocumentChecklistStore(documentId).saveChecklist();
          return;
        }

        // Otherwise we use the number of body paragraphs to generate the paragraphPositionData
        const paragraphPositionsData: ParagraphPositionData[] = [];

        for (let i = 0; i <= numBodyParagraphs.value; i++) {
          paragraphPositionsData.push({
            position: i,
            shortLabel: `P${i}`,
            longLabel: `Paragraph ${i}`,
            score: 0,
          });
        }

        criteriaInstance.paragraphPositionsData = paragraphPositionsData;
        criteriaParagraphPositionsData.value =
          criteriaInstance.paragraphPositionsData;

        useDocumentChecklistStore(documentId).saveChecklist();
      };

      populateParagraphPositionData();

      const criteriaParagraphPositions = computed(() => {
        return criteriaParagraphPositionsData.value?.map(
          (paragraphPositionData) => paragraphPositionData.position
        );
      });

      const numParagraphPositions = computed(() => {
        return criteriaParagraphPositionsData.value?.length ?? 0;
      });

      //   Points calculations
      const resetPoints = () => {
        if (criteriaParagraphPositionsData.value == undefined) return;

        for (var paragraphPositionData of criteriaParagraphPositionsData.value) {
          paragraphPositionData.score = 0;
          delete paragraphPositionData.manualScore;
        }
      };

      return {
        criteriaParagraphPositionsData,
        criteriaParagraphPositions,
        numParagraphPositions,
        resetPoints,
      };
    }
  );
