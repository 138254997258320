<script lang="ts" setup>
import { GuidedTour } from "classes/models/tours/guided-tour";

const tourBuilderStore = useTourBuilder();
const { isBuildingTour, isSelectingElement, guidedTour } =
  storeToRefs(tourBuilderStore);

const guidedTourStore = useGuidedTours();
const { showTourButton, tourToShow, restartableTours, hasActiveTour } =
  storeToRefs(guidedTourStore);

const startTour = (tour: GuidedTour) => {
  useGuidedTours().setTourToShow(undefined);
  useGuidedTours().startTour(tour);
};

const showTourButtons = computed(() => {
  if (restartableTours.value.length > 0) {
    return true;
  }

  if (tourToShow.value == undefined) return false;

  return tourToShow.value.tourPromptType == "button" && showTourButton.value;
});
</script>

<template>
  <div class="h-screen w-screen relative">
    <slot />
    <TransitionFade>
      <GuidedToursDialog
        v-if="
          tourToShow &&
          tourToShow.tourPromptType == 'dialog' &&
          showTourButton &&
          isBuildingTour != true
        "
        :guidedTour="tourToShow"
        :key="tourToShow.id"
      />
    </TransitionFade>
    <!-- <TransitionFade>
      <div
        v-if="
          showTourButtons && isBuildingTour != true && hasActiveTour != true
        "
        class="fixed z-[999] right-4 bottom-4 flex flex-row items-center"
      >
        <BaseFab class="mr-4" color="secondary" @click="guidedTourStore.clearToursToShow()">
          <icon name="material-symbols:close" />
        </BaseFab>
        <div
          v-for="guidedTour in restartableTours"
          class="rounded-full p-4 mr-2 bg-primary-default text-primary-on cursor-pointer hover:bg-primary-tint"
          @click="startTour(guidedTour)"
        >
          {{ guidedTour.restartButtonLabel }}
        </div>
        <div
          v-if="
            tourToShow != undefined && tourToShow.tourPromptType == 'button'
          "
          class="rounded-full p-4 mr-2 bg-secondary-default text-secondary-on cursor-pointer shadow hover:bg-secondary-tint"
          @click="startTour(tourToShow)"
        >
          {{ tourToShow.buttonLabel }}
        </div>
      </div>
    </TransitionFade> -->
    <TransitionFade>
      <div
        v-if="isBuildingTour"
        class="fixed z-[999] bottom-0 left-0 flex flex-col w-[500px] h-[90%] p-4"
        :class="{
          ' opacity-0 pointer-events-none': isSelectingElement == true,
        }"
      >
        <div
          class="flex-flex-col grow min-h-0 w-full bg-surface border-primary-default border-2 rounded"
        >
          <TourBuilderGuidedTourContainer
            v-if="guidedTour"
            :guidedTour="guidedTour"
            class="h-full"
          />
          <TourBuilderToursList class="h-full" v-if="guidedTour == undefined" />
        </div>
        <div
          class="mt-4 shrink-0 w-full text-center flex flex-col justify-center items-center"
        >
          <BaseFab @click="isBuildingTour = false" color="danger">
            <icon name="ic:baseline-close" size="20" />
          </BaseFab>
        </div>
      </div>
    </TransitionFade>
    <div
      v-if="isSelectingElement"
      id="element-selector-container"
      class="fixed top-0 left-0 h-screen w-screen z-[9999]"
    ></div>
  </div>
</template>

<style scoped></style>
