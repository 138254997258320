import {
  collection,
  onSnapshot,
  query,
  where,
  Unsubscribe,
} from "firebase/firestore";

export const useSystemComments = () =>
  definePiniaStore(`/system/comments`, () => {
    const comments = ref<QuickComments>([]);

    const isInitialized = ref(false);
    const subscription = ref<Unsubscribe | undefined>();

    const streamComments = () => {
      if (isInitialized.value) return;

      const db = useFirestore();
      const commentsRef = collection(db, "quickComments");

      if (subscription.value) {
        subscription.value();
      }

      subscription.value = onSnapshot(commentsRef, (snapshot) => {
        comments.value = snapshot.docs.map((doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          } as QuickComment;
        });
      });

      isInitialized.value = true;
    };

    type FilterParams = {
      documentId?: string;
      category?: string;
      tag?: string;
    };

    const filteredComments = computed(() => {
      return ({ documentId, category, tag }: FilterParams) => {
        let levelId = undefined as string | undefined;
        let lessonId = undefined as string | undefined;

        if (documentId) {
          try {
            const store = useDocumentChecklistStore(documentId);
            const { checklistLevelId, checklistLessonId, useChecklist } =
              storeToRefs(store);

            levelId = checklistLevelId.value;
            lessonId = checklistLessonId.value;
          } catch (error) {}
        }

        const matchingComments = comments.value.filter((comment) => {
          if (category != undefined) {
            if (comment.category != category) return false;
          }

          if (tag != undefined) {
            if (comment.tags?.includes(tag) != true) return false;
          }

          if (
            levelId &&
            comment.levels &&
            comment.levels.length > 0 &&
            comment.levels.includes(levelId) != true
          ) {
            return false;
          }

          if (
            lessonId &&
            comment.lessons &&
            comment.lessons.length > 0 &&
            comment.lessons.includes(lessonId) != true
          ) {
            return false;
          }

          return true;
        });

        matchingComments.sort((a, b) => {
          return a.priority - b.priority;
        });

        return matchingComments;
      };
    });

    const decorationComments = computed(() => {
      return (decorationTag: string, documentId?: string) => {
        return filteredComments.value({
          documentId,
          tag: decorationTag,
          category: "Decorations",
        });
      };
    });

    const annotationTypeComments = computed(() => {
      return (commentTag: string, documentId?: string) => {
        return filteredComments.value({
          documentId,
          tag: commentTag,
          category: "Annotation Types",
        });
      };
    });

    return {
      streamComments,
      filteredComments,
      decorationComments,
      annotationTypeComments,
    };
  });
