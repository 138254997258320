import { DBFactory } from "~/classes/database/db_factory";
import { BaseModel } from "../base.model";

export type StudentNotes = StudentNote[];

type StudentNoteConstructorParams = {
  id: string;
  teacherId: string;
  studentId: string;
  classroomId: string;
  notes: string;
};

export class StudentNote extends BaseModel {
  id: string;
  teacherId: string;
  studentId: string;
  classroomId: string;
  notes: string;

  constructor(params: StudentNoteConstructorParams) {
    super(params);

    this.id = params.id;
    this.teacherId = params.teacherId;
    this.studentId = params.studentId;
    this.classroomId = params.classroomId;
    this.notes = params.notes;
  }

  override get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: "/studentNotes",
      path: `/studentNotes/${this.id}`,
    };
  }

  static default({
    teacherId,
    studentId,
    classroomId,
  }: {
    teacherId: string;
    studentId: string;
    classroomId: string;
  }): StudentNote {
    return new StudentNote({
      id: studentId,
      teacherId,
      studentId,
      classroomId,
      notes: "",
    });
  }

  static fromMap(map: any): StudentNote {
    const instance = new StudentNote(map);
    return instance;
  }

  toMap(): ModelDatabaseData {
    return super.toMap();
  }

  async save() {
    const db = DBFactory.createDatabase();
    this.id = await db.save(this.toMap(), this.databaseConfig);
  }
}
