import {
  ChecklistCriteria,
  CriteriaInstance,
  ParagraphPositionData,
} from "types/Checklist";
import { EvaluationCriteria } from "types/EvaluationResults";
import { RevisionAnnotations } from "types/RevisionAnnotation";

export const useEarnedPoints = (
  manualPoints: number | undefined,
  computedEarnedPoints: number | undefined,
  method: string,
  criteriaParagraphPositionsData: ParagraphPositionData[] | undefined,
  checklistCriteria: ChecklistCriteria | undefined,
  criteriaInstance: CriteriaInstance,
  mechanicsCriteria: EvaluationCriteria[],
  mechanicsAnnotations: string[]
) => {
  // If we have a manual score we return that
  // Unless the method is paragraph based. Paragraph based criteria are always computed
  // from the paragraph data. Each paragraph can be manual but the criteria score cannot be manual otherwise
  // it will ignore the individual paragraph scores.
  if (
    manualPoints != undefined &&
    manualPoints != null &&
    method != "paragraph"
  ) {
    return manualPoints;
  }

  const hasParagraphPositions = criteriaParagraphPositionsData != undefined;

  if (method == "paragraph" && hasParagraphPositions) {
    var totalParagraphPoints = useSumParagraphData(
      criteriaParagraphPositionsData
    );

    return totalParagraphPoints;
  }

  let mechanicsDeductions = 0;

  if (checklistCriteria && checklistCriteria.isMechanicsCriteria == true) {
    for (var criteria of mechanicsCriteria) {
      if (criteria.passed == false) {
        mechanicsDeductions += (criteriaInstance.pointsPerInstance ?? 0);
      }
    }

    for (var annotation of mechanicsAnnotations) {
      mechanicsDeductions += (criteriaInstance.pointsPerInstance ?? 0);
    }
  }

  return (computedEarnedPoints ?? 0) + mechanicsDeductions;
};
