export const useDocumentSavingStatus = (
  documentId: string,
  revisionId: string
) =>
  definePiniaStore(
    `/documents/${documentId}/revision/${revisionId}/savingStatus`,
    () => {
      const numSavigs = ref(0);

      const save = () => {
        numSavigs.value++;
      };

      const saved = () => {
        var newValue = numSavigs.value - 1;
        numSavigs.value = Math.max(0, newValue);
      };

      const isSaving = computed(() => {
        return numSavigs.value > 0;
      });

      return { isSaving, save, saved };
    }
  );
