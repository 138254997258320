export default defineNuxtPlugin((nuxtApp) => {
  // if (process.client) {
  //   // Declare the _iub variable with its configuration
  //   let _iub = (window as any)._iub || [];
  //   _iub.csConfiguration = {
  //     applyGdprForCH: false,
  //     askConsentAtCookiePolicyUpdate: true,
  //     countryDetection: true,
  //     enableFadp: true,
  //     enableLgpd: true,
  //     enableUspr: true,
  //     floatingPreferencesButtonDisplay: "bottom-right",
  //     gdprAppliesGlobally: false,
  //     lang: "en",
  //     lgpdAppliesGlobally: false,
  //     perPurposeConsent: true,
  //     siteId: 3481241,
  //     whitelabel: false,
  //     cookiePolicyId: 43169763,
  //     banner: {
  //       acceptButtonDisplay: true,
  //       closeButtonDisplay: false,
  //       customizeButtonDisplay: true,
  //       explicitWithdrawal: true,
  //       listPurposes: true,
  //       position: "float-top-center",
  //       rejectButtonDisplay: true,
  //       showTitle: false,
  //     },
  //   };
  //   (window as any)._iub = _iub;
  //   // Function to dynamically load scripts
  //   const loadScript = (
  //     src: string,
  //     charset: string = "UTF-8",
  //     async: boolean = false
  //   ) => {
  //     const script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.src = src;
  //     script.charset = charset;
  //     script.async = async;
  //     document.head.appendChild(script);
  //   };
  //   // Load the Iubenda scripts
  //   loadScript("https://cs.iubenda.com/autoblocking/3481241.js");
  //   loadScript("//cdn.iubenda.com/cs/gpp/stub.js");
  //   loadScript("//cdn.iubenda.com/cs/iubenda_cs.js", "UTF-8", true);
  // }
});
