import { CriteriaInstance } from "types/Checklist";
import { useEvaluationResults } from "~/stores/useEvaluationResults";

export const useResultsForCriteriaIdStore = (
  documentId: string,
  criteriaInstance: CriteriaInstance
) => {
  const { revision } = useSubmittedDocumentStore(documentId);

  if (!revision) {
    throw new Error("Revision is not defined");
  }

  return useEvaluationResults(
    documentId,
    revision.id,
    criteriaInstance.checklistSectionId!,
    criteriaInstance.checklistCriteriaId!,
    criteriaInstance
  )();
};
