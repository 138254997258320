export enum AnnotationTypes {
  commentOnly = "commentOnly",
  bannedWord = "bannedWord",
  newParagraph = "newParagraph",
  capitalization = "capitalization",
  fragment = "fragment",
  runOn = "runOn",
  dontCapitalize = "dontCapitalize",
  insertComma = "insertComma",
  removeComma = "removeComma",
  insertPeriod = "insertPeriod",
  spellingError = "spellingError",
  numberError = "numberError",
  boldError = "boldError",
  underlineError = "underlineError",
  highlightError = "highlightError",
}

export const AllAnnotationTypes = [
  AnnotationTypes.commentOnly,
  AnnotationTypes.bannedWord,
  AnnotationTypes.newParagraph,
  AnnotationTypes.capitalization,
  AnnotationTypes.fragment,
  AnnotationTypes.runOn,
  AnnotationTypes.dontCapitalize,
  AnnotationTypes.insertComma,
  AnnotationTypes.removeComma,
  AnnotationTypes.insertPeriod,
  AnnotationTypes.spellingError,
  AnnotationTypes.numberError,
  AnnotationTypes.boldError,
  AnnotationTypes.underlineError,
  AnnotationTypes.highlightError,
];
