<script lang="ts" setup>
const emits = defineEmits(["choose-element"]);

const overlays = ref<
  {
    top: number;
    left: number;
    width: number;
    height: number;
    stepId: string;
  }[]
>([]);

const onMouseMove = (ev: MouseEvent) => {
  const elements = document.elementsFromPoint(ev.clientX, ev.clientY);

  const stepElements = elements.filter((element) =>
    element.hasAttribute("data-step-id")
  );

  stepElements.sort((a, b) => getDomDepth(a) - getDomDepth(b));

  overlays.value = stepElements.map((element) => {
    const elementRect = element.getBoundingClientRect();
    const stepId = (element as HTMLElement).dataset.stepId;

    return {
      top: elementRect.top,
      left: elementRect.left,
      width: elementRect.width,
      height: elementRect.height,
      stepId: stepId as string,
    };
  });
};

const childBuffer = 6;

const getDomDepth = (element: any) => {
  let depth = 0;
  while (element && element !== document.body) {
    depth++;
    element = element.parentNode;
  }
  return depth;
};

const onClickElement = (ev: MouseEvent) => {
  const stepId = (ev.target as HTMLElement).dataset.id;
  emits("choose-element", stepId);
};
</script>

<template>
  <Teleport to="#element-selector-container">
    <div
      class="w-screen h-screen pointer-events-auto fixed top-0 left-0"
      @mousemove="onMouseMove"
    >
      <div
        v-for="(overlay, index) in overlays"
        class="bg-primary-default bg-opacity-5 hover:bg-opacity-40 border-primary-default border-2 border-dashed cursor-pointer rounded fixed z-[99999]"
        :style="{
          top: overlay.top + index * (childBuffer / 2) + 'px',
          left: overlay.left + index * (childBuffer / 2) + 'px',
          width: overlay.width - index * childBuffer + 'px',
          height: overlay.height - index * childBuffer + 'px',
        }"
        :data-id="overlay.stepId"
        @click.stop.prevent="onClickElement"
      >
        &nbsp;
      </div>
    </div>
  </Teleport>
</template>

<style scoped></style>
