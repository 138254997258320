import { Delta } from "@vueup/vue-quill";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

// @ts-ignore
import toPlaintext from "quill-delta-to-plaintext";

export default defineNuxtPlugin((NuxtApp) => {
  NuxtApp.provide("createQuillDelta", (text: string) => {
    return new Delta().insert(text);
  });

  NuxtApp.provide("addToQuillDelta", (ops: any, text: string) => {
    return new Delta(ops).insert(text);
  });

  NuxtApp.provide("prependToQuillDelta", (ops: any, text: string) => {
    var delta = new Delta(ops);
    var newDelta = new Delta().insert(`${text} `);

    return delta.compose(newDelta);
  });

  NuxtApp.provide("removePrefixFromQuillDelta", (ops: any, prefix: string) => {
    var delta = new Delta(ops);
    // If there are no ops or the first op isn't an insert, return the delta unchanged
    if (!delta.ops || !delta.ops[0] || delta.ops[0].insert === undefined) {
      return delta;
    }

    // If the first insert starts with the prefix
    if (
      typeof delta.ops[0].insert === "string" &&
      delta.ops[0].insert.startsWith(prefix)
    ) {
      // Remove the prefix
      delta.ops[0].insert = delta.ops[0].insert.substring(prefix.length);

      // If the first insert is now empty, remove it
      if (delta.ops[0].insert === "") {
        delta.ops.shift();
      }
    }

    return delta;
  });

  NuxtApp.provide(
    "convertQuillDeltaToPlaintext",
    (ops: any, trim: boolean = true, includeLineBreaks: boolean = true) => {
      if (!ops) return "";

      var delta = new Delta(ops);
      var comment = toPlaintext(delta);

      return comment;
    }
  );

  NuxtApp.provide("convertQuillDeltaToHTML", (ops: any) => {
    const converter = new QuillDeltaToHtmlConverter(ops, {});
    let html = converter.convert();

    return html.replace(/\u00A0/g, " ");
  });

  NuxtApp.provide(
    "convertQuillDeltaToLines",
    (ops: any, trime: boolean = true) => {
      if (!ops) return "";

      var delta = new Delta(ops);
      var comment = toPlaintext(delta);

      return comment.split("\n");
    }
  );
});
