export const useGroupQuickComments = (
  quickComments: QuickComments
): { [key: string]: QuickComments } => {
  const grouped = quickComments.reduce<{ [key: string]: QuickComments }>(
    (acc, quickComment) => {
      let category = quickComment.category ?? "Uncategorized";
      category = category.trim();

      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(quickComment);
      return acc;
    },
    {}
  );

  // Sort each category's comments by priority
  Object.keys(grouped).forEach((category) => {
    grouped[category].sort((a, b) => {
      // Handle undefined priorities
      const priorityA =
        a.priority === undefined ? Number.MAX_SAFE_INTEGER : a.priority;
      const priorityB =
        b.priority === undefined ? Number.MAX_SAFE_INTEGER : b.priority;

      return priorityA - priorityB;
    });
  });

  return grouped;
};
