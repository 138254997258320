export const useApplyClassToTokens = (
  tokenGlobalPositions: number[],
  className: string,
  selector: string = ".inner-token"
) => {
  const spans = document.querySelectorAll(`.${className}`);
  spans.forEach((span) => {
    span.classList.remove(className);
  });

  for (var tokenPosition of tokenGlobalPositions) {
    const span = document.querySelector(
      `span[data-token-position="${tokenPosition}"] ${selector}`
    );

    if (span) {
      span.classList.add(className);
    }
  }
};
