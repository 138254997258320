import {
  DocumentData,
  QuerySnapshot,
  collection,
  doc,
  getDocs,
  onSnapshot,
  setDoc,
  writeBatch,
  Unsubscribe,
} from "firebase/firestore";
import {
  RevisionAnnotation,
  RevisionAnnotations,
} from "~/types/RevisionAnnotation";
import { useAnnotationFormState } from "~/stores/useAnnotationFormState";
import { AnnotationTypes } from "~/types/enums/AnnotationTypes.enum";
import { AnnotationType } from "types/AnnotationType";
import { useAnnotationTypesStore } from "~/composables/useAnnotationTypesStore";

export const useUserAnnotations = definePiniaStore(`userAnnotations`, () => {
  const annotations = ref<RevisionAnnotations>([]);

  const isInitialized = ref(false);

  const reset = () => {
    isInitialized.value = false;
    annotations.value = [];
  };

  const defaultAnnotationTypes = ref<AnnotationTypes[]>([
    AnnotationTypes.newParagraph,
    AnnotationTypes.fragment,
    AnnotationTypes.runOn,
    AnnotationTypes.capitalization,
    AnnotationTypes.insertPeriod,
  ]);

  const subscription = ref<Unsubscribe | undefined>();

  const initializeAnnotations = async () => {
    const db = useFirestore();

    if (isInitialized.value == true) {
      return;
    }

    const uid = useCurrentUID();

    if (!uid) {
      return null;
    }

    const annotationsCollectionRef = collection(
      db,
      `/users/${uid}/annotations`
    );

    // const collectionSnapshot = await getDocs(annotationsCollectionRef);
    // parseSnapshot(collectionSnapshot);

    if (subscription.value) {
      subscription.value();
    }

    subscription.value = onSnapshot(annotationsCollectionRef, parseSnapshot);

    isInitialized.value = true;
  };

  const nuxtApp = useNuxtApp();

  const parseSnapshot = (snapshot: QuerySnapshot<DocumentData>) => {
    annotations.value = snapshot.docs.map((doc) => {
      var annotation = {
        ...doc.data(),
      } as RevisionAnnotation;

      if (annotation.type && useAnnotationType(annotation.type)?.inlineSymbol) {
        // @ts-ignore
        annotation.commentObject = nuxtApp.$createQuillDelta(
          useAnnotationType(annotation.type)!.inlineSymbol
        ).ops;
      }

      return annotation;
    });
  };

  // Check if the annotations array contains an annotation of the given type and is enabled OR the annotations array does not contain an annotation of the given type
  const isAnnotationEnabled = (type: AnnotationTypes) => {
    const annotation = annotations.value.find((a) => a.type == type);

    if (!annotation) {
      return true;
    }

    return annotation.isEnabled;
  };

  const { createDefaultAnnotation } = useAnnotationFormState();

  const saveUserAnnotation = async (annotation: RevisionAnnotation) => {
    const db = useFirestore();

    const uid = useCurrentUID();

    if (!uid) {
      return;
    }

    const annotationRef = doc(
      db,
      `/users/${uid}/annotations/${annotation.type}`
    );

    await setDoc(annotationRef, {
      ...annotation,
      type: annotation.type,
    });
  };

  const saveUserAnnotations = async (annotations: RevisionAnnotations) => {
    const db = useFirestore();

    const uid = useCurrentUID();

    if (!uid) {
      return;
    }

    const batch = writeBatch(db);

    annotations.forEach((annotation) => {
      const annotationRef = doc(
        db,
        `/users/${uid}/annotations/${annotation.type}`
      );

      batch.set(annotationRef, {
        ...annotation,
        type: annotation.type,
      });
    });

    await batch.commit();
  };

  const annotationTypesStore = useAnnotationTypesStore();
  const { annotationTypes, annotationTypeComments } =
    storeToRefs(annotationTypesStore);

  // I want to map AnnotationType and if there is an entry in annotations with a matching type
  // Then I want to return the annotation otherwise, I want to return a new annotation with the type and isEnabled set to true
  const allAnnotations = computed(() => {
    return annotationTypes.value.map((type) => {
      const annotation = annotations.value.find((a) => a.type == type.value);

      if (annotation) {
        return {
          ...annotation,
          quickComments: annotationTypeComments
            .value(type)
            .map((c) => c.comment),
        };
      }

      return {
        ...createDefaultAnnotation(
          type,
          undefined,
          annotationTypeComments.value(type).map((c) => c.comment)
        ),
      };
    });
  });

  // Return all annotations where isEnabled is true
  const activeAnnotations = computed(() => {
    return allAnnotations.value.filter((a) => a.isEnabled);
  });

  const annotationDefinition = computed(() => {
    return (type: AnnotationTypes): RevisionAnnotation | undefined => {
      return (
        allAnnotations.value.find((a) => a.type == type) ??
        createDefaultAnnotation(
          useAnnotationType(type)!,
          undefined,
          annotationTypeComments
            .value(useAnnotationType(type)!)
            .map((c) => c.comment)
        )
      );
    };
  });

  // Return all annotations where isQuickAnnotation is true
  const quickAnnotations = computed(() => {
    return allAnnotations.value.filter(
      (a) => a.isQuickAnnotation && a.type != AnnotationTypes.commentOnly
    );
  });

  const annotationComments = computed(() => {
    return quickAnnotations.value
      .map((a) => {
        return a.quickComments.map((c) => {
          return `${useAnnotationType(a.type)!.prefix} ${c}`;
        });
      })
      .flat();
  });

  const defaultAnnotations = computed(() => {
    return allAnnotations.value.filter((a) =>
      defaultAnnotationTypes.value.includes(a.type)
    );
  });

  return {
    annotations,
    activeAnnotations,
    quickAnnotations,
    allAnnotations,
    annotationDefinition,
    annotationComments,
    defaultAnnotations,
    initializeAnnotations,
    isAnnotationEnabled,
    saveUserAnnotation,
    saveUserAnnotations,
    reset,
  };
});
