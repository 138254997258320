export enum EvaluationTestStatus {
  active = "active",
  inactive = "inactive",
  teacherReview = "teacherReview",
  requiresReview = "requiresReview",
  requiresDeveloperReview = "requiresDeveloperReview",
  readyForTesting = "readyForTesting",
}

export const EvaluationTestStatuses = [
  {
    label: "Active",
    value: EvaluationTestStatus.active,
  },
  {
    label: "Inactive",
    value: EvaluationTestStatus.inactive,
  },
  {
    label: "Teacher Review",
    value: EvaluationTestStatus.teacherReview,
  },
  {
    label: "Requires Review",
    value: EvaluationTestStatus.requiresReview,
  },
  {
    label: "Requires Developer Review",
    value: EvaluationTestStatus.requiresDeveloperReview,
  },
  {
    label: "Ready for Testing",
    value: EvaluationTestStatus.readyForTesting,
  },
];
