import { Paragraphs } from "types/Paragraph";

export const useTrimParagraphs = (paragraphs: Paragraphs) => {
  // If the input is null, undefined or not an array, return an empty array.
  if (!Array.isArray(paragraphs)) {
    return [];
  }

  // Clone the array to avoid mutating the original
  const trimmedParagraphs = [...paragraphs];

  // Start from the end and remove empty paragraphs
  while (
    trimmedParagraphs.length > 0 &&
    (trimmedParagraphs[trimmedParagraphs.length - 1].tokens.length === 0 ||
      trimmedParagraphs[trimmedParagraphs.length - 1].text == "\n" ||
      // replacea all \n amd \t
      trimmedParagraphs[trimmedParagraphs.length - 1]?.text?.replace(
        /[\n\t]/g,
        ""
      ).length == 0 ||
      trimmedParagraphs[trimmedParagraphs.length - 1].text == "")
  ) {
    trimmedParagraphs.pop();
  }

  // In here we'll also clean up body paragraphs
  for (var paragraph of trimmedParagraphs) {
    if (paragraph.section != "body") continue;

    if (
      paragraph.text == "\n" ||
      paragraph.text == "" ||
      paragraph.text.replace(/^ +| +$/g, "") == "\n" ||
      paragraph.text.replace(/^ +| +$/g, "") == ""
    ) {
      paragraph.section = "filler";
    }

    if (paragraph.tokens.length == 0) {
      paragraph.section = "filler";
    }

    const filteredTokens = paragraph.tokens.filter((token) => {
      return (
        token.type != "filler" && token.type != "space" && token.text != "\n"
      );
    });

    if (filteredTokens.length == 0) {
      paragraph.section = "filler";
    }
  }

  return trimmedParagraphs;
};
