<script lang="ts" setup>
const isOnline = ref(true);

onMounted(() => {
  window.addEventListener("online", () => {
    isOnline.value = true;
  });

  window.addEventListener("offline", () => {
    isOnline.value = false;
  });
});
</script>

<template>
  <div class="relative">
    <slot />
    <div
      v-if="isOnline != true"
      class="fixed top-0 left-0 z-[99999] border-danger-default border-8 w-screen h-screen pointer-events-none"
    >
      <div
        class="bg-danger-default rounded fixed top-0 left-[50%] -translate-x-[50%] text-danger-on p-4 text-center"
      >
        Oops! It looks like you are offline. Please check your internet
        connection.
      </div>
    </div>
  </div>
</template>

<style scoped></style>
