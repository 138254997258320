// import { FirestoreDatabase } from "./firestore_database";

import { FirestoreDatabase } from "./firestore_database";
import { FirestoreServerDatabase } from "./firestore_server_database";

export class DBFactory {
  static createDatabase() {
    const dbProvider = process.env.DATABASE_PROVIDER;

    switch (dbProvider) {
      case "FIRESTORE":
      default:
        return FirestoreDatabase.createDatabase();
    }
  }
}
