import { Observable } from "rxjs";
import { BaseModel } from "~/classes/models/base.model";

export class DBAbstract {
  async get(databaseConfig: ModelDatabaseConfig): Promise<ModelDatabaseData> {
    throw new Error("Method 'get' must be implemented in database class.");
  }

  generateId(databaseConfig: ModelDatabaseConfig): string {
    throw new Error(
      "Method 'generateId' must be implemented in database class."
    );
  }

  async list(
    databaseConfig: ModelDatabaseConfig,
    queryData?: ModelQueryConfig
  ): Promise<ModelDatabaseData[]> {
    throw new Error("Method 'list' must be implemented in database class.");
  }

  streamRecord(
    databaseConfig: ModelDatabaseConfig
  ): Observable<ModelDatabaseData | undefined> {
    throw new Error(
      "Method 'streamRecord' must be implemented in database class."
    );
  }

  streamList(
    databaseConfig: ModelDatabaseConfig,
    queryData?: ModelQueryConfig
  ): Observable<ModelDatabaseData[]> {
    throw new Error(
      "Method 'streamList' must be implemented in database class."
    );
  }

  async save(
    data: ModelDatabaseData,
    config: ModelDatabaseConfig,
    merge: boolean = true
  ): Promise<string> {
    throw new Error("Method 'save' must be implemented in database class.");
  }

  async delete(databaseConfig: ModelDatabaseConfig) {
    throw new Error("Method 'delete' must be implemented in database class.");
  }

  async batchUpdate(models: BaseModel[]) {
    throw new Error(
      "Method 'batchUpdate' must be implemented in database class."
    );
  }

  async batchDelete(models: BaseModel[]) {
    throw new Error(
      "Method 'batchDelete' must be implemented in database class."
    );
  }
}
