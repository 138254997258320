import { jwtDecode } from 'jwt-decode';

export const useApiHeaders = async () => {
  const currentUser = await useCurrentUser();

  let idToken = await currentUser?.value?.getIdToken();

  if (idToken) {
    // Decode the token to check its expiration time
    const decodedToken: any = jwtDecode(idToken);
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

    // If the token is expired or will expire in less than 5 minutes, refresh it
    if (decodedToken.exp - currentTime < 300) {
      idToken = await currentUser?.value?.getIdToken(true);  // Refresh the token
    }
  }

  if (!idToken) {
    throw createError("Unauthorized.");
  }

  const headers = {
    authToken: idToken!,
  } as any;

  const pseudoLoginStore = usePseudoLogin();
  if (pseudoLoginStore.isPseduLogin === true) {
    headers.pseudologinid = pseudoLoginStore.pseudoId;
  }

  return headers;
};
