<script lang="ts" setup>
import { GuidedTour } from "classes/models/tours/guided-tour";
import { VueDraggableNext } from "vue-draggable-next";

const props = defineProps({
  guidedTour: {
    type: Object as PropType<GuidedTour>,
    required: true,
  },
});

const activeStepIndex = ref<number | undefined>(undefined);

const activeStep = computed(() => {
  if (activeStepIndex.value == undefined) return undefined;
  return props.guidedTour.steps[activeStepIndex.value];
});

const addStep = () => {
  props.guidedTour.steps.push({
    title: "New Step",
    instructions: "",
  });
};

const removeStep = (index: number) => {
  props.guidedTour.steps.splice(index, 1);
};

const viewStep = (index?: number) => {
  activeStepIndex.value = index;
};

const isSaving = ref(false);
const save = async () => {
  isSaving.value = true;
  await props.guidedTour.save();
  isSaving.value = false;
};
</script>

<template>
  <div class="flex flex-col">
    <div
      v-if="activeStep == undefined"
      class="flex flex-col py-4 overflow-auto h-full"
    >
      <div class="flex flex-row mb-2 items-center px-1">
        <BaseIconButton @click="useTourBuilder().editTour()" class="mr-2">
          <icon name="material-symbols:arrow-back" />
        </BaseIconButton>
        <BaseTextInput v-model="guidedTour.name" class="grow mr-2" />
        <BaseTextButton @click="save" :showSpinner="isSaving">
          Save
        </BaseTextButton>
      </div>

      <div class="px-4 mt-2">
        <div class="my-2" v-if="guidedTour.id">
          {{ guidedTour.id }}
        </div>
        <BaseFormComponent v-if="guidedTour.tourPromptType == 'button'">
          <template #label> Restrict to path? (optional) </template>
          <BaseTextInput v-model="guidedTour.restrictedPath" />
        </BaseFormComponent>
        <BaseFormComponent>
          <template #label> Tour Prompt Type </template>
          <BaseSelect
            :options="['button', 'dialog']"
            v-model="guidedTour.tourPromptType"
          />
        </BaseFormComponent>
        <BaseFormComponent v-if="guidedTour.tourPromptType == 'button'">
          <template #label> Button Label (optional) </template>
          <BaseTextInput v-model="guidedTour.buttonLabel" />
        </BaseFormComponent>
        <BaseFormComponent v-if="guidedTour.tourPromptType == 'button'">
          <template #label> Button Class (optional) </template>
          <BaseTextInput v-model="guidedTour.buttonRightPosition" />
        </BaseFormComponent>
        <div v-if="guidedTour.tourPromptType == 'dialog'">
          <BaseFormComponent>
            <template #label> Dialog Title (optional) </template>
            <BaseTextInput v-model="guidedTour.dialogTitle" />
          </BaseFormComponent>
          <BaseFormComponent>
            <template #label> Dialog Body </template>
            <BaseTextArea v-model="guidedTour.dialogText" />
          </BaseFormComponent>
        </div>
        <BaseFormComponent>
          <template #label> Allow restart? </template>
          <BaseToggle v-model="guidedTour.allowRestart" />
        </BaseFormComponent>
        <TransitionsCollapsible>
          <BaseFormComponent v-if="guidedTour.allowRestart">
            <template #label> Restart Button Label </template>
            <BaseTextInput v-model="guidedTour.restartButtonLabel" />
          </BaseFormComponent>
        </TransitionsCollapsible>
        <div class="flex flex-row justify-between border-b py-1 mb-4">
          <h2 class="text-h5">Steps</h2>
          <BaseIconButton @click="addStep">
            <icon name="material-symbols:add-circle-outline" size="20" />
          </BaseIconButton>
        </div>
        <VueDraggableNext :list="guidedTour.steps">
          <div
            v-for="(step, index) in guidedTour.steps"
            :key="index"
            class="p-2 border-b last:border-none flex flex-row justify-between items-center hover:bg-light-default cursor-pointer"
            @click="viewStep(index)"
          >
            <div class="flex flex-row items-center">
              <BaseIconButton
                color="danger"
                @click.stop.remove="removeStep(index)"
                class="mr-2"
              >
                <icon name="mdi:trash" />
              </BaseIconButton>
              <span>
                {{ index + 1 }}.
                {{ step.title }}
              </span>
            </div>
            <icon name="material-symbols:chevron-right-rounded" />
          </div>
        </VueDraggableNext>
      </div>
    </div>
    <TourBuilderGuidedTourStep
      v-if="activeStep"
      :guidedTourStep="activeStep"
      @back="viewStep()"
      class="h-full"
    />
  </div>
</template>

<style scoped></style>
