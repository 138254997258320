
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {},
  "firebaseConfig": {
    "apiKey": "AIzaSyDLl-_8-nXfk5ROy19-UNGr6jVCnEye5nM",
    "authDomain": "iew-gradebook.firebaseapp.com",
    "projectId": "iew-gradebook",
    "storageBucket": "iew-gradebook.appspot.com",
    "messagingSenderId": "691306366690",
    "appId": "1:691306366690:web:58774295b18b6eb63f80bb",
    "measurementId": "G-Q09N5GDJT4"
  },
  "vuefireOptions": {
    "optionsApiPlugin": false,
    "config": {
      "apiKey": "AIzaSyDLl-_8-nXfk5ROy19-UNGr6jVCnEye5nM",
      "authDomain": "iew-gradebook.firebaseapp.com",
      "projectId": "iew-gradebook",
      "storageBucket": "iew-gradebook.appspot.com",
      "messagingSenderId": "691306366690",
      "appId": "1:691306366690:web:58774295b18b6eb63f80bb",
      "measurementId": "G-Q09N5GDJT4"
    },
    "auth": true
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
