import { DBFactory } from "classes/database/db_factory";
import { BaseModel } from "../base.model";
import { ZoomOauthScope } from "~/types/enums/ZoomOauthScopes.enum";

type ZoomOauthTokenConstructorParams = {
  userId: string;
  scope: ZoomOauthScope[];
  accessToken: string;
  expiryTimestamp: number;
  refreshToken: string;
  bearer: string;
};

export class ZoomOauthToken extends BaseModel {
  userId: string;
  scope: ZoomOauthScope[];
  accessToken: string;
  expiryTimestamp: number;
  refreshToken: string;
  bearer: string;

  constructor(params: ZoomOauthTokenConstructorParams) {
    super(params);
    this.userId = params.userId;
    this.scope = params.scope;
    this.accessToken = params.accessToken;
    this.expiryTimestamp = params.expiryTimestamp;
    this.refreshToken = params.refreshToken;
    this.bearer = params.bearer;
  }

  toMap(): ModelDatabaseData {
    return {
      id: this.userId,
      userId: this.userId,
      scope: this.scope,
      accessToken: this.accessToken,
      expiryTimestamp: this.expiryTimestamp,
      refreshToken: this.refreshToken,
      bearer: this.bearer,
    };
  }

  static fromMap(map: any) {
    return new ZoomOauthToken(map);
  }

  get databaseConfig(): ModelDatabaseConfig {
    return {
      collection: `/zoomOAuthTokens`,
      path: `/zoomOAuthTokens/${this.userId}`,
    };
  }

  async save() {
    const db = DBFactory.createDatabase();
    await db.save(this.toMap(), this.databaseConfig);
  }

  async delete() {
    const db = DBFactory.createDatabase();
    await db.delete(this.databaseConfig);
  }
}
