import {
  collection,
  query,
  onSnapshot,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  getDoc,
  writeBatch,
  DocumentData,
  QuerySnapshot,
  Unsubscribe,
} from "firebase/firestore";

export const useUserQuickComments = definePiniaStore(
  "userQuickComments",
  () => {
    const isInitialized = ref(false);

    const quickComments = ref<QuickComment[]>([]);

    const subscription = ref<Unsubscribe | undefined>();

    const reset = () => {
      isInitialized.value = false;
      quickComments.value = [];
    };

    const generateQuickCommentId = () => {
      const uid = useCurrentUID();

      if (!uid) {
        return "";
      }

      const db = useFirestore();
      const quickCommentsRef = collection(db, `/users/${uid}/quickComments`);
      const quickCommentRef = doc(quickCommentsRef);
      return quickCommentRef.id;
    };

    const getUserQuickcomments = async () => {
      const uid = useCurrentUID();

      if (!uid) {
        return { quickCommentsStream: undefined };
      }

      const db = useFirestore();
      const quickCommentsRef = collection(db, `/users/${uid}/quickComments`);
      const quickCommentsQuery = query(quickCommentsRef);
      // const quickCommentsSnapshots = await getDocs(quickCommentsQuery);

      // populateQuickComments(quickCommentsSnapshots);

      if (subscription.value) {
        subscription.value();
      }

      subscription.value = onSnapshot(quickCommentsQuery, (snapshot) => {
        populateQuickComments(snapshot);
      });
    };

    const populateQuickComments = (snapshot: QuerySnapshot<DocumentData>) => {
      quickComments.value = snapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        } as QuickComment;
      });
    };

    const initializeUserQuickComments = async () => {
      if (isInitialized.value == true) {
        return;
      }

      await getUserQuickcomments();

      isInitialized.value = true;
    };

    const fetchQuickComment = async (id: string) => {
      const db = useFirestore();
      const uid = useCurrentUID();

      if (!uid) {
        return;
      }

      const quickCommentsRef = doc(db, `/users/${uid}/quickComments/${id}`);
      const quickCommentsSnapshot = await getDoc(quickCommentsRef);
      return {
        ...quickCommentsSnapshot.data(),
        id: quickCommentsSnapshot.id,
      } as QuickComment;
    };

    const categorizeQuickComments = (quickComments: QuickComments) => {
      return useCategorizeQuickComments(quickComments);
    };

    const importAllComments = async (quickComments: QuickComments) => {
      const db = useFirestore();

      const uid = useCurrentUID();

      if (!uid) {
        return;
      }

      const batch = writeBatch(db);
      for (const comment of quickComments) {
        const quickCommentsRef = doc(
          db,
          `/users/${uid}/quickComments/${comment.id}`
        );
        batch.set(quickCommentsRef, comment);
      }

      await batch.commit();
    };

    const saveQuickComment = async (quickComment: QuickComment) => {
      const db = useFirestore();

      const uid = useCurrentUID();

      if (!uid) {
        return;
      }

      if (quickComment.id) {
        const quickCommentsRef = doc(
          db,
          `/users/${uid}/quickComments/${quickComment.id}`
        );
        await updateDoc(quickCommentsRef, quickComment);
      } else {
        const quickCommentsRef = collection(db, `/users/${uid}/quickComments`);
        const response = await addDoc(quickCommentsRef, quickComment);
        quickComment.id = response.id;
      }
    };

    const saveQuickComments = async (quickComments: QuickComments) => {
      const db = useFirestore();
      const batch = writeBatch(db);

      const uid = useCurrentUID();

      if (!uid) {
        return;
      }

      for (const quickComment of quickComments) {
        const quickCommentsRef = doc(
          db,
          `/users/${uid}/quickComments/${quickComment.id}`
        );
        batch.set(quickCommentsRef, quickComment);
      }

      await batch.commit();
    };

    const deleteQuickComment = async (quickComment: QuickComment) => {
      const db = useFirestore();

      const uid = useCurrentUID();
      if (!uid) {
        return;
      }

      if (quickComment.id) {
        const quickCommentsRef = doc(
          db,
          `/users/${uid}/quickComments/${quickComment.id}`
        );
        await deleteDoc(quickCommentsRef);
      }
    };

    const bulkAddQuickComments = async (quickComments: QuickComments) => {
      const uid = useCurrentUID();

      if (!uid) {
        return;
      }

      const db = useFirestore();

      const batch = writeBatch(db);

      for (const quickComment of quickComments) {
        const quickCommentsRef = collection(db, `/users/${uid}/quickComments`);
        const quickCommentRef = doc(quickCommentsRef);
        batch.set(quickCommentRef, quickComment);
      }

      await batch.commit();
    };

    const categories = computed(() => {
      const categories = useGroupQuickComments(quickComments.value);
      return Object.keys(categories).sort();
    });

    const createQuickComment = () => {
      const db = useFirestore();
      const quickCommentDocRef = collection(
        db,
        `/users/${useCurrentUID()}/quickComments`
      );

      return {
        id: doc(quickCommentDocRef).id,
        lessons: [] as string[],
        levels: [] as string[],
        priority: 0,
      } as QuickComment;
    };

    return {
      quickComments,
      categories,
      generateQuickCommentId,
      initializeUserQuickComments,
      fetchQuickComment,
      categorizeQuickComments,
      importAllComments,
      saveQuickComment,
      saveQuickComments,
      deleteQuickComment,
      bulkAddQuickComments,
      createQuickComment,
      reset,
    };
  }
);
