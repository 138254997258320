import { AnnotationType } from "types/AnnotationType";
import { RevisionAnnotation } from "~/types/RevisionAnnotation";

export const useAnnotationFormState = definePiniaStore(
  "annotation/form/state",
  () => {
    const annotationBeingEdited = ref<RevisionAnnotation | undefined>();

    const setAnnotationBeingEdited = (
      documentId: string,
      annotation: RevisionAnnotation | undefined
    ) => {
      if (annotation && annotation.isEndLine != true) {
        const { setSelection } = useDocumentSelectionStore(documentId);

        const firstToken = useAnnotationFirstToken(annotation);
        const lastToken = useAnnotationLastToken(annotation);
        if (firstToken && lastToken) {
          setSelection(firstToken, lastToken);
        }
      }

      annotationBeingEdited.value = annotation;
    };

    const setAnnotationBeingInlineEdited = (
      documentId: string,
      annotation: RevisionAnnotation | undefined
    ) => {
      const { setSelection, clearSelection } =
        useDocumentSelectionStore(documentId);

      if (annotation && annotation.isEndLine != true) {
        const firstToken = useAnnotationFirstToken(annotation);
        const lastToken = useAnnotationLastToken(annotation);
        if (firstToken && lastToken) {
          setSelection(firstToken, lastToken);
        }
      }

      if (!annotation) {
        clearSelection();
      }

      annotationBeingInlineEdited.value = annotation;
    };

    const updateAnnotationType = (type: AnnotationType) => {
      if (annotationBeingEdited.value) {
        annotationBeingEdited.value.type = type.value;
      }
    };

    const isAnnotationBeingEdited = computed(() => {
      return annotationBeingEdited.value !== undefined;
    });

    const isThisAnnotationBeingEdited = computed(() => {
      return (annotation: RevisionAnnotation) => {
        return annotation.id === annotationBeingEdited.value?.id;
      };
    });

    const nuxtApp = useNuxtApp();
    const createDefaultAnnotation = (
      type?: AnnotationType,
      paragraphId?: string,
      defaultComments?: string[]
    ): RevisionAnnotation => {
      let commentObject = undefined;

      if (type && type.inlineSymbol) {
        // @ts-ignore
        commentObject = nuxtApp.$createQuillDelta(type.inlineSymbol).ops;
      }

      return {
        displayOrder: 0,
        isEnabled: true,
        isQuickAnnotation: false,
        lastUpdatedTimestamp: new Date().getTime(),
        requiresInput: false,
        requiresStudentCorrection: false,
        shortcutCharacter: "",
        shortcutKeyId: "",
        tokenGlobalPositions: [],
        paragraphId: paragraphId ?? null,
        startId: 0,
        type: type?.value ?? AnnotationTypes.commentOnly,
        forceInline: false,
        forceAfterToken: false,
        isEndLine: false,
        isSmartAnnotation: false,
        quickComments: defaultComments ?? [],
        commentObject,
        isBeforeParagraph: false,
        isStartLine: false,
      };
    };

    const annotationBeingInlineEdited = ref<RevisionAnnotation | undefined>(
      undefined
    );

    const isAnnotationBeingInlineEdited = computed(() => {
      return (annotation: RevisionAnnotation) => {
        return annotation.id === annotationBeingInlineEdited.value?.id;
      };
    });

    const isAnAnnotationBeingInlineEdited = computed(() => {
      return annotationBeingInlineEdited.value !== undefined;
    });

    return {
      annotationBeingEdited,
      annotationBeingInlineEdited,
      isAnnotationBeingEdited,
      isThisAnnotationBeingEdited,
      isAnnotationBeingInlineEdited,
      isAnAnnotationBeingInlineEdited,
      setAnnotationBeingEdited,
      setAnnotationBeingInlineEdited,
      updateAnnotationType,
      createDefaultAnnotation,
    };
  }
);
