import { RevisionAnnotation } from "types/RevisionAnnotation";

export const useAnnotationLastToken = (annotation: RevisionAnnotation) => {
  if (
    !annotation.tokenGlobalPositions ||
    annotation.tokenGlobalPositions.length == 0
  ) {
    return undefined;
  }

  var tokenPositions = annotation.tokenGlobalPositions;

  // Ensure numerical sorting
  tokenPositions.sort((a, b) => a - b);

  var lastTokenPosition = tokenPositions[tokenPositions.length - 1];

  // If the first token position is negative or not a number, return undefined
  if (lastTokenPosition < 0 || isNaN(lastTokenPosition)) {
    return undefined;
  }

  return lastTokenPosition;
};
